import * as React from "react";
import { lazy, Suspense, useState, useEffect, useRef } from "react";
// @mui material components
import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Ícono para estado contraído
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BorderColor, Rotate } from "@mui/icons-material";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import Box, { boxClasses } from "@mui/material/Box";
import { Modal } from "@mui/material";

import "../../assets/fonts/fonts.css"; // Archivo que contiene la declaración @font-face
import Whatsapp from "./sections/whatsapp";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";

import Testimonials from "pages/Presentation/sections/Testimonials";
import SelectorDinamico from "pages/Presentation/sections/Filtros";
import ConfigFilter from "pages/Presentation/sections/ConfigFilter";

//import Productos from 'pages/Presentation/sections/productos';
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import FadeInSection from "pages/Presentation/sections/FadeInSection"; // Asegúrate de que la ruta sea correcta
import HoverEffectGrid from "pages/Presentation/sections/Ambientals"; // Asegúrate de que la ruta sea correcta
import Comp247 from "./sections/247";
import LazyBackgroundImage from "./sections/backgroundImage";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import img_360 from "assets/images/DALL·E 2024-2.webp";
// Images
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgHome from "assets/images/bghome.svg";
import revo from "assets/images/revo-1.webp";
import zIndex from "@mui/material/styles/zIndex";
import boxShadow from "assets/theme/functions/boxShadow";
import Button from "assets/theme/components/button";
const WhyAinaCar_ = lazy(() => import("pages/Presentation/sections/PorQue"));
const CustomCarouselComponent = lazy(() =>
  import("pages/Presentation/sections/BlogKode")
);
const Productos = lazy(() => import("pages/Presentation/sections/productos"));

function Presentation() {
  const [resetProductos, setResetProductos] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [productos, setProductos] = useState([]);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [carConfig, setCarConfig] = useState({});
  const [carConfig360, setCarConfig360] = useState({});

  const scrollToRef = (ref) => {
    if (ref.current) {
      const yOffset = -100; // Ajuste de 200px antes del componente
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const productRef = useRef(null);

  const handleScrollToProductRef = () => {
    scrollToRef(productRef);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (productRef.current) {
      handleScrollToProductRef();
    }
  };

  const handleCarSet360 = (
    modeloSeleccionado,
    versionSeleccionada,
    colorSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado,
    equipamientoSeleccionado
  ) => {
    //console.log('Selected configuration 360:', { modeloSeleccionado, versionSeleccionada, colorSeleccionado, motorSeleccionado, cambioSeleccionado, mediosSeleccionado, equipamientoSeleccionado });
    const newConfig360 = {
      modeloSeleccionado,
      versionSeleccionada,
      colorSeleccionado,
      motorSeleccionado,
      cambioSeleccionado,
      mediosSeleccionado,
      equipamientoSeleccionado,
    };
    setCarConfig360(newConfig360);
  };

  const handleCarSet = (
    typeSeleccionado,
    modeloSeleccionado,
    versionSeleccionada,
    colorSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado
  ) => {
    const newConfig = {
      typeSeleccionado,
      modeloSeleccionado,
      versionSeleccionada,
      colorSeleccionado,
      motorSeleccionado,
      cambioSeleccionado,
      mediosSeleccionado,
    };
    //console.log('Selected configuration:', newConfig);
    setCarConfig(newConfig);
  };

  const [valorHijo, setValorHijo] = useState("");

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
  };
  //const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const isMobileDevice = () => {
    return window.innerWidth <= 500; // Puedes ajustar este valor según tus necesidades
  };

  const isSmallDevice = () => {
    return window.innerWidth <= 1000; // Puedes ajustar este valor según tus necesidades
  };

  const [aplicarAjustes, setAplicarAjustes] = useState(false);

  const alternarAplicacionAjustes = () => {
    setAplicarAjustes((prevState) => !prevState);
  };

  const obtenerProductos2 = async () => {
    try {
      const response = await axios.get(
        "https://kodeapp.onrender.com/api/productos/"
      );
      if (response.data.productos && response.data.productos.length > 0) {
        setProductos(response.data.productos); // Aquí no es necesario hacer JSON.parse
      } else {
        console.error(
          "La respuesta del backend no contiene productos válidos."
        );
      }
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  useEffect(() => {
    obtenerProductos2();
  }, []);

  const whyAinaCarRef = useRef(null);

  const handleScrollToWhyAinaCar = () => {
    scrollToRef(whyAinaCarRef);
  };
  return (
    <>
      <Whatsapp />
      <MKBox bgColor="info">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              //borderRadius: '12px',
              //top: '50%',
              //left: '50%',
              //transform: 'translate(-50%, -50%)',
              width: "100vw",
              height: "100vh",
              backgroundColor: "#181818",
              //boxShadow: 24,

              outline: "none",
            }}
          >
            <IconButton
              variant="contained"
              sx={{
                color: "#FFD415",
                position: "absolute",
                zIndex: 1,
                top: 0,
                left: 0,
                position: "absolute",
                margin: "16px",
              }}
              onClick={() => {
                alternarAplicacionAjustes();
                handleClose();
              }}
            >
              {aplicarAjustes ? (
                <CloseIcon
                  style={
                    {
                      //transform: 'rotate(225deg)'
                    }
                  }
                />
              ) : null}
            </IconButton>
            <ConfigFilter
              carSetSon360={handleCarSet360}
              modalClose={handleClose}
            />
          </Box>
        </Modal>

        {/* Barra de navegación */}
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : isMobileDevice() ? "100px" : "80px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          <ResponsiveAppBar prodRef={handleScrollToProductRef} onCambio={manejarCambio} />
        </Box>
        <MKBox
          sx={{
            zIndex: 1,
            position: "relative",
            minHeight: "95vh",
            width: "100%",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex", // Usar display flex
            justifyContent: "center", // Centrar horizontalmente el contenido
            alignItems: "center", // Centrar verticalmente el contenido
            marginTop: `-${appBarHeight}px`, // Properly concatenate appBarHeight
            "::before": {
              content: '""',
              zIndex: 0,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `
                        linear-gradient(to bottom, rgba(24, 24, 24, 1) 2%, rgba(0, 0, 0, 0) 20%), 

        linear-gradient(to top, rgba(24, 24, 24, 1) 10%, rgba(0, 0, 0, 0) 70%)
      `,
            },
          }}
        >
          <Comp247 sx={{ zIndex: 2 }} />

          <Container>
            <Grid
              sx={{ marginTop: isSmallDevice() ? "0px" : "100px" }}
              container
              xs={12}
              lg={5}
              justifyContent="center"
              mx="auto"
            >
              <MKBox mb={0}>
                <MKTypography
                  sx={{
                    fontFamily: "atomic-regular",
                    letterSpacing: "0.15em",
                    color: "#FFD415",
                    transform: "rotate(-2deg)",
                    fontSize: {
                      xs: "1.5rem",
                      sm: "2rem",
                      md: "3rem",
                      lg: "4rem",
                      xl: "4rem",
                    },
                    animation: "blink-animation 0.2s 3 1s", // Agrega la animación
                  }}
                  variant="h6"
                  align="center"
                  gutterBottom
                >
                  ÚNETE A LA
                </MKTypography>
              </MKBox>
              <MKBox
                mb={-3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <MKTypography
                  sx={{
                    fontFamily: "atomic-regular",
                    letterSpacing: "0.15em",
                    color: "#ffffff",
                    marginTop: "-10px", // Ajusta la separación superior según sea necesario
                    transform: "rotate(-2deg)",
                    fontSize: {
                      xs: "3.5rem",
                      sm: "4rem",
                      md: "6rem",
                      lg: "8rem",
                      xl: "8rem",
                    },
                    animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                  }}
                  variant="h1"
                  align="center"
                  gutterBottom
                >
                  REVOLUCIÓN
                </MKTypography>
              </MKBox>
              <style>
                {`
        @keyframes blink-animation {
          0% { color: #FFD415; }
          50% { color: #000000; }
          100% { color: #FFD415; }
        }
      `}
                {`
        @keyframes blink-animation2 {
          0% { color: #ffffff; }
          50% { color: #000000; }
          100% { color: #ffffff; }
        }
      `}
              </style>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center" // Alinea el texto al centro
                mt={{ xs: 3, lg: 1 }}
                sx={{
                  zIndex: 3,
                  textShadow: "2px 2px 4px  rgba(0., 0, 0, 0.)",
                }}
              >
                Consigue los mejores precios con entrega en 30 días
              </MKTypography>
            </Grid>

            {isSmallDevice() ? null : (
              <Container
                mt={0}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <Card
                  style={{
                    width: "180px",
                    backgroundColor: "transparent",
                    border: "1px solid #FFD415",

                    padding: "10px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    alternarAplicacionAjustes();
                    handleOpen();
                  }}
                >
                  <ThreeSixtyIcon
                    style={{
                      color: "#FFD415",
                      fontSize: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <MKTypography
                    variant="body2"
                    sx={{ color: "#FFD415", fontSize: "65%" }}
                  >
                    Configurador 360°
                  </MKTypography>
                </Card>
              </Container>
            )}
          </Container>
        </MKBox>
        <MKBox
          bgColor="info"
          zIndex={2}
          textAlign="center"
          position="relative"
          display="flex" // Agregar la propiedad display:flex
          alignItems="center" // Centrar verticalmente los elementos
          justifyContent="center"
          style={{ minHeight: "5vh", cursor: "pointer" }}
          onClick={handleScrollToWhyAinaCar}
        >
          <MKTypography style={{}} color="white" variant="h6">
            Más información
          </MKTypography>
          <IconButton variant="contained">
            <ExpandMoreIcon style={{ color: "white" }} />
          </IconButton>
        </MKBox>
        <MKBox
          zIndex={2}
          textAlign="center"
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "5vh", top: '-10vh' }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <WhyAinaCar_ refe={whyAinaCarRef} />
          </Suspense>
        </MKBox>
        <FadeInSection>
          <CustomCarouselComponent />
        </FadeInSection>
        <MKBox sx={{}}>
          <Box //sx={{maxWidth:'100%',  border: '3px solid #a8a8a8', borderRadius: '30px'}}
          >
            <FadeInSection>
              <Counters />
            </FadeInSection>
          </Box>
        </MKBox>
        <MKBox mt={7} mb={15}>
          <Box //sx={{maxWidth:'100%',  border: '3px solid #a8a8a8', borderRadius: '30px'}}
          >
            <FadeInSection>
              <HoverEffectGrid />
            </FadeInSection>
          </Box>
        </MKBox>
        <MKBox py={12} bgColor="info">
          <Box
            sx={{ flexGrow: 1, px: 4, maxWidth: "1650px", margin: "0 auto" }}
          >
            <MKBox mb={0}>
              <MKTypography
                sx={{
                  fontFamily: "atomic-regular",
                  letterSpacing: "0.15em",
                  color: "#FFD415",
                  transform: "rotate(-2deg)",
                  fontSize: {
                    xs: "2.5rem",
                    sm: "3rem",
                    md: "4rem",
                    lg: "4rem",
                    xl: "4rem",
                  },
                }}
                variant="h1"
                align="center"
                gutterBottom
              >
                POOL DE COCHES DISPONIBLES
              </MKTypography>
            </MKBox>
            <MKBox mb={4} style={{ display: "flex", justifyContent: "center" }}>
              <MKTypography
                sx={{
                  fontFamily: "atomic-regular",
                  letterSpacing: "0.15em",
                  color: "#ffffff",
                  marginTop: "-10px", // Ajusta la separación superior según sea necesario
                  transform: "rotate(-2deg)",
                  fontSize: {
                    xs: "1.5rem",
                    sm: "1.5rem",
                    md: "2rem",
                    lg: "3rem",
                    xl: "3rem",
                  }, // Ajusta el tamaño de la fuente según lo necesario
                }}
                variant="h1"
                align="center"
                gutterBottom
              >
                BY KODE CARS MARKET
              </MKTypography>
            </MKBox>
            <MKBox
              mb={15}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <MKBox sx={{ width: "60%" }}>
                <MKBox mb={4} sx={{}}>
                  <MKTypography
                    sx={{
                      //fontFamily: 'atomic-regular',
                      //letterSpacing: "0.15em",
                      color: "#ffffff",
                      marginTop: "-10px", // Ajusta la separación superior según sea necesario
                      //transform: 'rotate(-2deg)',

                      //fontWeight: 'Regular'
                    }}
                    variant="body2"
                    align="center"
                    gutterBottom
                  >
                    Todos los vehículos que te mostramos a continuación están
                    disponibles en stock y pueden ser entregados en un máximo de
                    30 días, sujeto a disponibilidad (1).
                  </MKTypography>
                </MKBox>

                <MKTypography
                  sx={{
                    //fontFamily: 'atomic-regular',
                    //letterSpacing: "0.15em",
                    color: "#ffffff",
                    marginTop: "-10px", // Ajusta la separación superior según sea necesario
                    //transform: 'rotate(-2deg)',
                  }}
                  variant="body2"
                  align="center"
                  gutterBottom
                >
                  (1) Puede darse el caso de que dos o más personas estén
                  interesadas en el mismo vehículo. La prioridad se otorgará a
                  quien formalice primero el pedido.
                </MKTypography>
              </MKBox>
            </MKBox>

            <Grid container justifyContent="center" spacing={3}>
              <Grid xs={12} sm={3.5} lg={3}>
                <Card
                  sx={{
                    //minHeight: "620px",
                    p: 2,
                    mx: { lg: 1 },
                    mb: 0,
                    backgroundColor: "#181818",
                    boxShadow: "none", //'0 8px 16px rgba(0, 0, 0, 0.5)', // Aumenta la oscuridad de la sombra
                    border: "1px solid  rgba(168, 168, 168, 0.35)", // Añade una línea en el borde, cambia '#3f51b5' por el color que prefieras
                  }}
                >
                  {isSmallDevice() ? null : (
                    <Box
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Card
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          border: "1px solid #FFD415",

                          padding: "10px",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          alternarAplicacionAjustes();
                          handleOpen();
                        }}
                      >
                        <ThreeSixtyIcon
                          style={{
                            color: "#FFD415",
                            fontSize: "25px",
                            marginRight: "10px",
                          }}
                        />
                        <MKTypography
                          variant="body2"
                          sx={{ color: "#FFD415", fontSize: "65%" }}
                        >
                          Configurador 360°
                        </MKTypography>
                      </Card>
                    </Box>
                  )}

                  <Accordion mt={2}
                  defaultExpanded={!isSmallDevice()}
                    sx={{
                      boxShadow: "none",
                      backgroundColor: "#181818",
                      marginTop:isSmallDevice() ? '0px':'25px'
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                      //sx={{ mb: 0,}} // Anula el margin-bottom para eliminar el separador
                    >
                      <MKTypography
                        variant="h5"
                        sx={{ color: "#FFFFFF",  }}
                      >
                        <h2 style={{ color: "#FFD415" }}>Filtros</h2>
                      </MKTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box p={2} sx={{ width: "100%", margin: "0 auto" }}>
                        <SelectorDinamico carSetSon={handleCarSet} />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Card>
              
              </Grid>
              <Grid xs={12} sm={8.5}>
                <Productos
                  refep={productRef}
                  carConfigR={carConfig}
                  carConfigR360={carConfig360}
                />
              </Grid>
            </Grid>
          </Box>
        </MKBox>
        <MKBox bgColor="info" pt={6} px={1} mt={0}>
          <CenteredFooter />
        </MKBox>
      </MKBox>
    </>
  );
}

export default Presentation;

/*{isMobileDevice() ? (null) : (<IconButton variant="contained" sx={{color:'#FFD415'}} onClick={() => {alternarAplicacionAjustes(); handleOpen()}}>
      {aplicarAjustes ? <ExpandLessIcon style={{ transform: 'rotate(225deg)' }} /> : <ExpandMoreIcon style={{ transform: 'rotate(225deg)' }}/>}
      </IconButton>) }*/
