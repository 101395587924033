import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/backets-2.webp";
import backets from "assets/images/backets.webp";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";
import BlogCard from "./blogCard";
import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function KodeBlog() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [blogsFilter, setBlogsFilter] = useState('');

  const obtenerColores = async () => {
    try {
      const response = await fetch(`https://kodeapp.onrender.com/api/blog`);
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      //console.log("blogs", data.blogs);
      setBlogs(data.blogs || []);
    } catch (error) {
      console.error("Error al obtener los detalles del blog:", error);
    }
  };

  useEffect(() => {
    obtenerColores();
  }, []);

  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  /*const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };*/

  return (
    <>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "59vh",
            width: "100%",
            //backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgRenault})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={
                  {
                    //marginTop: {
                    //  xs: `${appBarHeight * 2}px`,
                    //  sm: `${appBarHeight * 2}px`,
                    //  md: `${appBarHeight * 2}px`,
                    //  lg: `${appBarHeight}px`,
                    //},
                  }
                }
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      sx={{
                        fontFamily: "atomic-regular",
                        letterSpacing: "0.15em",
                        color: "#FFD415",
                        transform: "rotate(-2deg)",
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "4rem",
                          xl: "10rem",
                        },
                      }}
                      variant="h1"
                      align="center"
                      gutterBottom
                    >
                      BLOG
                    </MKTypography>
                    <Box mt={-8}>
                      <MKTypography
                        sx={{
                          fontFamily: "atomic-regular",
                          letterSpacing: "0.15em",
                          color: "#ffffff",
                          marginTop: "-10px",
                          transform: "rotate(-2deg)",
                          fontSize: {
                            xs: "1.5rem",
                            sm: "1.5rem",
                            md: "2rem",
                            lg: "3rem",
                            xl: "2rem",
                          }, // Ajusta el tamaño de la fuente según lo necesario
                        }}
                        variant="h1"
                        align="center"
                        gutterBottom
                      >
                        KODE CARS MARKET
                      </MKTypography>
                    </Box>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>
        <Container 
          sx={{
            mt: {
              xs: -6,
              sm: -6,
              md: -6,
              lg: -16,
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.07)",
            padding: "10px",
            borderRadius: "8px",
            width: "80%",

          }}
        >
          <Grid
            container
            sx={{
              width: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             
            }}
            spacing={2}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={3}
            >
              
              <Button onClick={() => {setBlogsFilter('tecno y tendencias')}}sx={{color:'#FFFFFF'}}>tecno y tendencias</Button>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={3}
            >
              
              <Button onClick={() => {setBlogsFilter('detrás del volante')}} sx={{color:'#FFFFFF'}}>detrás del volante</Button>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={3}
            >
              
              <Button onClick={() => {setBlogsFilter('actualidad')}} sx={{color:'#FFFFFF'}}>actualidad</Button>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={3}
            >
              
              <Button onClick={() => {setBlogsFilter('trucos y consejos')}} sx={{color:'#FFFFFF'}}>trucos y consejos</Button>

            </Grid>
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {blogs.slice(0, 3).map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card
                  style={{
                    backgroundColor: "#181818",
                    boxShadow: "none",
                    minHeight: "49vh",
                  }}
                >
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        padding: "16px",
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={backets}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "12px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h4
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h4>
                        <br />
                        <MKBox>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      justifyContent: "flex-end",
                      bottom: 2,
                      right: 2,
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(`/blog/${text.id}/`);
                      }}
                      variant="text"
                      sx={{ color: "rgba(255,255,255,0.5)" }}
                    >
                      Más información
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        
        <Container sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 8,
            },
            mb: 8,
            backgroundColor: "white",
          }}>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
            >
              {blogs.slice(2, 3).map((text, index) => (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Card
                    style={{
                      backgroundColor: "#181818",
                      boxShadow: "none",
                      minHeight: "49vh",
                    }}
                  >
                    <MKBox
                      position="relative"
                      mx={0}
                      mt={0}
                      mb={0}
                      overflow="hidden"
                    >
                      <div
                        style={{
                          padding: "16px",
                          overflow: "hidden",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      >
                        <img
                          src={backets}
                          alt={"fff"}
                          style={{
                            maxHeight: "250px",
                            width: "100%",
                            borderRadius: "12px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </MKBox>
                    <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                      <MKBox
                        mt={0}
                        mb={3}
                        mx={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <MKBox flex={1} sx={{ textAlign: "left" }}>
                          <h4
                            display="inline"
                            variant="h4"
                            style={{
                              color: "#FFD415",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {text.title}
                          </h4>
                          <br />
                          <MKBox>
                            <MKTypography
                              variant="body2"
                              sx={{ color: "#ffffff", textAlign: "justify" }}
                            >
                              {text.subtitle}
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        justifyContent: "flex-end",
                        bottom: 2,
                        right: 2,
                      }}
                    >
                      <Button
                        onClick={() => {
                          navigate(`/blog/${text.id}/`);
                        }}
                        variant="text"
                        sx={{ color: "rgba(255,255,255,0.5)" }}
                      >
                        Más información
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
            >
              {blogs.slice(2, 3).map((text, index) => (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Card
                    style={{
                      backgroundColor: "#181818",
                      boxShadow: "none",
                      minHeight: "49vh",
                    }}
                  >
                    <MKBox
                      position="relative"
                      mx={0}
                      mt={0}
                      mb={0}
                      overflow="hidden"
                    >
                      <div
                        style={{
                          padding: "16px",
                          overflow: "hidden",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      >
                        <img
                          src={backets}
                          alt={"fff"}
                          style={{
                            maxHeight: "250px",
                            width: "100%",
                            borderRadius: "12px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </MKBox>
                    <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                      <MKBox
                        mt={0}
                        mb={3}
                        mx={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <MKBox flex={1} sx={{ textAlign: "left" }}>
                          <h4
                            display="inline"
                            variant="h4"
                            style={{
                              color: "#FFD415",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {text.title}
                          </h4>
                          <br />
                          <MKBox>
                            <MKTypography
                              variant="body2"
                              sx={{ color: "#ffffff", textAlign: "justify" }}
                            >
                              {text.subtitle}
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        justifyContent: "flex-end",
                        bottom: 2,
                        right: 2,
                      }}
                    >
                      <Button
                        onClick={() => {
                          navigate(`/blog/${text.id}/`);
                        }}
                        variant="text"
                        sx={{ color: "rgba(255,255,255,0.5)" }}
                      >
                        Más información
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
        
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {blogs.slice(0, 3).map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card
                  style={{
                    backgroundColor: "#181818",
                    boxShadow: "none",
                    minHeight: "49vh",
                  }}
                >
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        padding: "16px",
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={backets}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "12px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h4
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h4>
                        <br />
                        <MKBox>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      justifyContent: "flex-end",
                      bottom: 2,
                      right: 2,
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(`/blog/${text.id}/`);
                      }}
                      variant="text"
                      sx={{ color: "rgba(255,255,255,0.5)" }}
                    >
                      Más información
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        {/* Cookie Policy Section */}
        <Container>
          <Grid
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
            >
              {blogs.slice(2, 3).map((text, index) => (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Card
                    style={{
                      backgroundColor: "#181818",
                      boxShadow: "none",
                      minHeight: "49vh",
                    }}
                  >
                    <MKBox
                      position="relative"
                      mx={0}
                      mt={0}
                      mb={0}
                      overflow="hidden"
                    >
                      <div
                        style={{
                          padding: "16px",
                          overflow: "hidden",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      >
                        <img
                          src={backets}
                          alt={"fff"}
                          style={{
                            maxHeight: "250px",
                            width: "100%",
                            borderRadius: "12px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </MKBox>
                    <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                      <MKBox
                        mt={0}
                        mb={3}
                        mx={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <MKBox flex={1} sx={{ textAlign: "left" }}>
                          <h4
                            display="inline"
                            variant="h4"
                            style={{
                              color: "#FFD415",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {text.title}
                          </h4>
                          <br />
                          <MKBox>
                            <MKTypography
                              variant="body2"
                              sx={{ color: "#ffffff", textAlign: "justify" }}
                            >
                              {text.subtitle}
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        justifyContent: "flex-end",
                        bottom: 2,
                        right: 2,
                      }}
                    >
                      <Button
                        onClick={() => {
                          navigate(`/blog/${text.id}/`);
                        }}
                        variant="text"
                        sx={{ color: "rgba(255,255,255,0.5)" }}
                      >
                        Más información
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              lg={6}
            >
              {blogs.slice(2, 3).map((text, index) => (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Card
                    style={{
                      backgroundColor: "#181818",
                      boxShadow: "none",
                      minHeight: "49vh",
                    }}
                  >
                    <MKBox
                      position="relative"
                      mx={0}
                      mt={0}
                      mb={0}
                      overflow="hidden"
                    >
                      <div
                        style={{
                          padding: "16px",
                          overflow: "hidden",
                          borderTopLeftRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      >
                        <img
                          src={backets}
                          alt={"fff"}
                          style={{
                            maxHeight: "250px",
                            width: "100%",
                            borderRadius: "12px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </MKBox>
                    <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                      <MKBox
                        mt={0}
                        mb={3}
                        mx={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <MKBox flex={1} sx={{ textAlign: "left" }}>
                          <h4
                            display="inline"
                            variant="h4"
                            style={{
                              color: "#FFD415",
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {text.title}
                          </h4>
                          <br />
                          <MKBox>
                            <MKTypography
                              variant="body2"
                              sx={{ color: "#ffffff", textAlign: "justify" }}
                            >
                              {text.subtitle}
                            </MKTypography>
                          </MKBox>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        justifyContent: "flex-end",
                        bottom: 2,
                        right: 2,
                      }}
                    >
                      <Button
                        onClick={() => {
                          navigate(`/blog/${text.id}/`);
                        }}
                        variant="text"
                        sx={{ color: "rgba(255,255,255,0.5)" }}
                      >
                        Más información
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {blogs.slice(0, 3).map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card
                  style={{
                    backgroundColor: "#181818",
                    boxShadow: "none",
                    minHeight: "49vh",
                  }}
                >
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        padding: "16px",
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={backets}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "12px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h4
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h4>
                        <br />
                        <MKBox>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                  <Box
                    sx={{
                      display: "flex",
                      position: "absolute",
                      justifyContent: "flex-end",
                      bottom: 2,
                      right: 2,
                    }}
                  >
                    <Button
                      onClick={() => {
                        navigate(`/blog/${text.id}/`);
                      }}
                      variant="text"
                      sx={{ color: "rgba(255,255,255,0.5)" }}
                    >
                      Más información
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodeBlog;
