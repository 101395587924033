import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import TextField from "@mui/material/TextField";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Breadcrumbs from "examples/Breadcrumbs";
import routes from "routes";
import footerRoutes from "footer.routes";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgImage from "assets/images/bg-presentation-renault-2.jpg";
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import MKBadge from "components/MKBadge";
import LogoSVG from "assets/images/kode_logo.svg";
import abstract from "assets/images/abstract.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CenteredBlogCardEquip from "examples/Cards/BlogCards/CenteredBlogCardEquip";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import icon_engine from "assets/images/icon-engine.png";
import icon_gear from "assets/images/icon-gear.png";
import icon_delivery from "assets/images/icon-delivery.png";
const publi =
  "Todos nuestros vehículos están en stock, por ello garantizamos entrega en 30 días";

function ProductDetailMobile() {
  const appBarRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { id } = useParams();
  const [producto, setProducto] = useState(null);
  const [emisiones, setEmisiones] = useState(null);

  const [mostrarLogo, setMostrarLogo] = useState(false);
  const horarios = ["Mañana", "Tarde", "Noche"]; // Ejemplo de horarios
  const provinces = ["Provincia 1", "Provincia 2", "Provincia 3"]; // Ejemplo de provincias
  const [formData, setFormData] = useState({
    nombre: "",
    telefono: "",
    email: "",
    modelo: "",
    horario: "",
    provincia: "",
  });

  const [appBarHeight, setAppBarHeight] = useState(0);
  const [valorHijo, setValorHijo] = useState("");

  const [price, setPrice] = useState(0);
  const [cuote, setCuote] = useState(0);

  useEffect(() => {
    const ambientValue = parseInt(emisiones, 10);

    if (ambientValue <= 110) {
      setPrice(0);
      setCuote(25.21);
    } else if (ambientValue <= 159) {
      setPrice(35.63);
      setCuote(26.2);
    } else if (ambientValue <= 199) {
      setPrice(73.13);
      setCuote(27.24);
    } else {
      setPrice(120);
      setCuote(28.54);
    }
  }, [emisiones]);

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
  };

  // Estado para almacenar la página actual
  const [currentPage, setCurrentPage] = useState(1);

  // Función para cambiar de página
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const obtenerDetallesProducto = async () => {
      try {
        const response = await fetch(
          `https://kodeapp.onrender.com/api/productos/${id}/`
        );
        if (!response.ok) {
          throw new Error("No se pudieron cargar los detalles del producto");
        }
        const data = await response.json();
        setProducto(data.producto);
        setEmisiones(
          JSON.parse(data.producto.Caracteristicas_Ad)[0][3][
            "CONSUMOS Y EMISIONES"
          ]["CO2 ciclo mixto WLTP (g/km)"]
        );
        //console.log("prduct", data.producto);
      } catch (error) {
        console.error("Error al obtener los detalles del producto:", error);
      }
    };

    obtenerDetallesProducto();
  }, [id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMostrarLogo(true);
    }, 3000); // Cambia a mostrar el logo después de 2 segundos

    return () => clearTimeout(timeout);
  }, []);

  if (!producto) {
    return (
      <MKBox
        sx={{
          backgroundColor: "#181818",
          width: "100%",
          height: "100vh",
          display: "flex", // Utiliza flexbox
          justifyContent: "center", // Centra horizontalmente
          alignItems: "center", // Centra verticalmente
        }}
      >
        <MKTypography
          sx={{
            fontFamily: "atomic-regular",
            letterSpacing: "0.15em",
            color: "#FFD415",
            transform: "rotate(-2deg)",
            fontSize: {
              xs: "1rem",
              sm: "1rem",
              md: "2rem",
              lg: "3rem",
              xl: "3rem",
            },
            animation: "blink-animation 0.2s 3 1s", // Agrega la animación
          }}
          variant="h6"
          align="center"
          gutterBottom
        >
          Cargando
        </MKTypography>
      </MKBox>
    );
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
  };

  // Array para almacenar las URLs de las imágenes del coche
  const carImages = [];

  // Generar las URLs de las imágenes y agregarlas al array
  for (let i = 0; i <= 23; i = i + 2) {
    const imageUrl = require(`assets/images/vehiculos/${producto.product_id}/${producto.product_id}-${i}.jpeg`);
    carImages.push(imageUrl);
  }

  const matrix = JSON.parse(producto.Caracteristicas);
  const carColor = matrix[0][0];
  const tapizeria = matrix[0][1];

  const matrix_ad = JSON.parse(producto.Caracteristicas_Ad);

  const matrix_Equipamiento = JSON.parse(producto.Equipamiento);

  // Define el número de tarjetas por página
  const cardsPerPage = 1;

  // Calcula el número total de páginas
  const totalPages = Math.ceil(matrix_Equipamiento[0].length / cardsPerPage);

  // Calcula el índice inicial y final de las tarjetas en la página actual
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  // Filtra las tarjetas para mostrar solo las de la página actual
  const visibleCards = matrix_Equipamiento[0].slice(startIndex, endIndex);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:8000/api/contact_form/", formData);
      alert("Mensaje enviado exitosamente");
      setFormData({
        nombre: "",
        telefono: "",
        email: "",
        modelo: "",
        horario: "",
        provincia: "",
      });
    } catch (error) {
      console.error("Error:", error);
      alert("Error al enviar el mensaje");
    }
  };

  return (
    <>
        <Helmet>
        <title>{producto.Modelo}{" "}{producto.Version}</title>
      </Helmet>
      <MKBox sx={{ backgroundColor: "#181818" }}>
        <Box
          ref={appBarRef}
          style={{ position: "sticky", top: "15px", zIndex: 3 }}
        >
          <ResponsiveAppBar onCambio={manejarCambio} />
        </Box>
        <MKBox
          minHeight="15vh"
          width="100%"
          sx={{
            backgroundImage: `url(${abstract})`,
            backgroundColor: "#181818",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={7}>
                {mostrarLogo ? (
                  <>
                    <MKBox mb={0}>
                      <MKTypography
                        sx={{
                          fontFamily: "atomic-regular",
                          letterSpacing: "0.15em",
                          color: "#FFD415",
                          transform: "rotate(-2deg)",
                          fontSize: {
                            xs: "1rem",
                            sm: "1rem",
                            md: "2rem",
                            lg: "3rem",
                            xl: "3rem",
                          },
                          animation: "blink-animation 0.2s 3 1s", // Agrega la animación
                        }}
                        variant="h6"
                        align="center"
                        gutterBottom
                      >
                        ÚNETE A LA
                      </MKTypography>
                    </MKBox>
                    <MKBox
                      mb={-3}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <MKTypography
                        sx={{
                          fontFamily: "atomic-regular",
                          letterSpacing: "0.15em",
                          color: "#ffffff",
                          marginTop: "-10px", // Ajusta la separación superior según sea necesario
                          transform: "rotate(-2deg)",
                          fontSize: {
                            xs: "2.5rem",
                            sm: "3rem",
                            md: "4rem",
                            lg: "6rem",
                            xl: "6rem",
                          },
                          animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                        }}
                        variant="h1"
                        align="center"
                        gutterBottom
                      >
                        REVOLUCIÓN
                      </MKTypography>
                    </MKBox>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Ajusta la altura del contenedor para que el logo esté centrado verticalmente
                      marginTop: "20px", // Agrega un margen superior para bajar el logo
                    }}
                  >
                    <img
                      src={LogoSVG}
                      alt="Logo"
                      style={{
                        width: "300px", // ajusta el tamaño del logo
                        height: "auto",
                        //filter: "invert(1)", // cambia el color del logo a negro
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <Box sx={{ flexGrow: 1, p: 4, mt: -5 }}>
          <Grid container spacing={3} sx={{ mt: -3 }}>
            <Grid xs={12} sm={3}>
              <Box sx={{ p: 0, mx: { lg: 1 }, mb: 0 }} ml={-2}>
                <MKBox component="section" bgColor="transparent" py={0}>
                  {!isMobile ? (
                    <Breadcrumbs
                      sx={{ p: 0, mt: 0 }}
                      routes={[
                        { label: "Home", route: "/" },
                        { label: producto.Modelo },
                      ]}
                    />
                  ) : null}
                </MKBox>
              </Box>
            </Grid>
            <Grid xs={12} sm={9}></Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, p: 4, mt: -4 }}>
          <Grid sx={{}} container spacing={3}>
          <Grid xs={12} sm={6}>
              
              <Box p={0}>
                <Slider {...sliderSettings}>
                  {carImages.map((imageUrl, index) => (
                    <div
                      key={index}
                      style={{
                        height: "90%",
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          maxWidth: "900px",
                          maxHeight: "900px",
                          overflow: "hidden",
                          borderRadius: "15px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            paddingBottom: "80%",
                            backgroundImage: `url(${imageUrl || bgImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </Box>
            </Grid>
            <Grid xs={12} sm={3}>
              <Card
                sx={{
                  //boxShadow: "none",
                  backgroundColor: "#181818",
                  p: 0,
                  mx: { lg: 1 },
                  mb: 0,
                  mt:1
                  //border: "1px solid rgba(255, 212, 21, 0.35)"
                }}
              >
                <Box p={0}>
                  <MKBox p={0} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <MKTypography
                          display="inline"
                          variant="h4"
                          sx={{
                            color: "#ffffff",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {producto.Modelo}
                        </MKTypography>
                        <MKTypography
                          variant="body2"
                          sx={{ color: "#ffffff", fontSize: "75%" }}
                        >
                          {producto.Version}
                        </MKTypography>
                      </MKBox>
                      <MKBox>
                        <img
                          src={producto.Portada[0]["medio"]}
                          style={{
                            width: "100%",
                            borderRadius: "0",
                            objectFit: "cover",
                          }}
                        />
                      </MKBox>
                    </MKBox>
                    <Box
                      mb={3}
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "#FFD415",
                        width: "100%",
                        mt: 1,
                        py: 1,
                      }}
                    >
                      <MKTypography
                        variant="body2"
                        sx={{
                          color: "#031b27",
                          fontSize: "60%",
                          fontWeight: "bold",
                        }}
                      >
                        Precio Renault: {producto.Portada[0]["precio"]}
                      </MKTypography>
                    </Box>
                    <MKBox
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mb={1}
                      sx={{
                        borderRadius: "6px",
                        padding: "6px",
                        position: "relative",
                      }}
                    >
                      <img
                        src={icon_delivery}
                        style={{
                          width: "24px",
                          marginLeft: "10px",
                          marginRight: "15px",
                        }}
                      />
                      <MKTypography
                        variant="caption"
                        component="p"
                        sx={{ fontSize: "small", color: "#ffffff" }}
                      >
                        {producto.Portada[0]["entrega"]}
                      </MKTypography>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#a8a8a8",
                          opacity: "30%",
                          borderRadius: "6px",
                        }}
                      ></Box>
                    </MKBox>
                    <Divider variant="middle" />
                    <MKBox
                      sx={{ minHeight: "50px" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt={-2}
                      mb={0}
                    >
                      <MKBox
                        flex={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          src={icon_engine}
                          style={{ width: "24px", marginLeft: "10px" }}
                        />
                        <MKTypography
                          sx={{ ml: 2, color: "#a8a8a8" }}
                          variant="body2"
                        >
                          {producto.Portada[0]["motor"]}
                        </MKTypography>
                      </MKBox>
                      <MKBox
                        flex={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img src={icon_gear} style={{ width: "24px" }} />
                        <MKTypography
                          sx={{ ml: 2, color: "#a8a8a8" }}
                          variant="body2"
                        >
                          {producto.Portada[0]["cambio"]}
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKBox
                      mt={0}
                      mb={0}
                      mx={0}
                      p={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginleft: "0px",
                        }}
                      >
                        <h6
                          style={{
                            color: "#ffffff",
                            fontWeight: 400,
                            marginRight: "0px",
                            fontSize: "1.2rem",
                          }}
                        >
                          {" "}
                          {(
                            parseFloat(
                              producto.Portada[0]["precio"]
                                .split(" ")[0]
                                .replace(/\./g, "")
                                .replace(",", ".")
                            ) -
                            (907.5 + price)
                          )
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                          €
                        </h6>
                      </MKBox>
                      <MKBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <h5 style={{ color: "#ffffff", fontSize: "1.4rem" }}>
                          {" "}
                          {(
                            parseFloat(
                              producto.Portada[0]["cuota"]
                                .split("/")[0]
                                .replace(/\./g, "")
                                .replace(",", ".")
                            ) - cuote
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                          €
                        </h5>
                        <h6
                          style={{
                            color: "#ffffff",
                            fontWeight: 400,
                            marginLeft: "5px",
                          }}
                        >
                          {"/Mes"}
                        </h6>
                      </MKBox>
                    </MKBox>
                    <MKBox mt={0} mb={3}>
                      <MKTypography
                        variant="caption"
                        component="p"
                        sx={{ fontSize: "x-small", color: "#a8a8a8" }}
                      >
                        {producto.Portada[0]["espec"]}
                      </MKTypography>
                      <MKTypography
                        variant="caption"
                        component="p"
                        sx={{ fontSize: "x-small", color: "#a8a8a8" }}
                      >
                        precio y entrega recomendado sujeto a oferta final
                      </MKTypography>
                    </MKBox>
                  </MKBox>

                  <Divider sx={{ backgroundColor: "#FFFFFF" }} />
                  <Box p={2}>
                    <Box display="flex" sx={{ mt: 2 }}>
                      <Box flex={1}>
                        <h6
                          style={{
                            color: "#FFFFFF",
                            fontWeight: 600,
                            fontSize: "0.9rem",
                          }}
                        >
                          Color
                        </h6>
                      </Box>
                      <Box flex={1} sx={{ textAlign: "center" }}>
                        <h6
                          style={{
                            color: "#a8a8a8",
                            fontWeight: 400,
                            fontSize: "0.9rem",
                          }}
                        >
                          {carColor}
                        </h6>
                      </Box>
                    </Box>

                    <Box display="flex" sx={{ mt: 1 }}>
                      <Box flex={1}>
                        <h6
                          style={{
                            color: "#FFFFFF",
                            fontWeight: 600,
                            fontSize: "0.9rem",
                          }}
                        >
                          Tapicería
                        </h6>
                      </Box>
                      <Box flex={1} sx={{ textAlign: "center" }}>
                        <h6
                          style={{
                            color: "#a8a8a8",
                            fontWeight: 400,
                            fontSize: "0.9rem",
                          }}
                        >
                          {tapizeria}
                        </h6>
                      </Box>
                    </Box>
                    {matrix_ad[0].map((elemento, index) => {
                      const keys = Object.keys(elemento);
                      const values = Object.values(elemento);

                      return (
                        <Accordion
                          key={index}
                          sx={{
                            boxShadow: "none",
                            "&:before": { display: "none" },
                            backgroundColor: "#181818",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "#ffffff" }} />
                            }
                            aria-controls={`panel${index + 1}-content`}
                            id={`panel${index + 1}-header`}
                            sx={{ mb: 0, ml: -2 }} // Anula el margin-bottom para eliminar el separador
                          >
                            <MKTypography
                              variant="h5"
                              sx={{ color: "#FFFFFF", fontWeight: 400 }}
                            >
                              {
                                <h5
                                  style={{
                                    color: "#FFFFFF",
                                    fontWeight: 400,
                                    fontSize: "0.9rem",
                                  }}
                                >
                                  {keys[0]
                                    .toLowerCase()
                                    .replace(/^\w/, (c) => c.toUpperCase())}
                                </h5>
                              }
                            </MKTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {values.map((elemento, index) => {
                              const keys2 = Object.keys(elemento);
                              const values2 = Object.values(elemento);

                              // Recorrer keys2 y values2 para mostrar cada clave con su valor
                              return keys2.map((key2, idx) => (
                                <React.Fragment key={idx}>
                                  <Box display="flex" sx={{ mt: 1 }}>
                                    <Box flex={1}>
                                      <h6
                                        style={{
                                          color: "#FFFFFF",
                                          fontWeight: 600,
                                          fontSize: "0.9rem",
                                        }}
                                      >
                                        {/* Mostrar la clave individual */}
                                        {key2}
                                      </h6>
                                    </Box>
                                    <Box flex={1} sx={{ textAlign: "center" }}>
                                      <h6
                                        style={{
                                          color: "#a8a8a8",
                                          fontWeight: 400,
                                          fontSize: "0.9rem",
                                        }}
                                      >
                                        {/* Mostrar el valor correspondiente */}
                                        {values2[idx]}
                                      </h6>
                                    </Box>
                                  </Box>
                                  {/* Añadir Divider entre cada elemento */}
                                  <Divider
                                    sx={{ backgroundColor: "#FFFFFF", my: 1 }}
                                  />
                                </React.Fragment>
                              ));
                            })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                </Box>
              </Card>
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#181818",
                  p: 0,
                  mx: { lg: 1 },
                  mb: 3,
                  mt:1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#181818",
                }}
              >
                <Box p={1}>
                  <h5 style={{ color: "#ffffff" }}>{publi}</h5>
                </Box>
              </Card>
            </Grid>
            <Grid xs={12} sm={3}>
              
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#181818",
                  p: 0,
                  mx: { lg: 1 },
                  mb: 0,
                  mt:1
                }}
              >
                <Box p={0}>
                  <h3 style={{ color: "#FFFFFF" }}>Equipamiento</h3>

                  {matrix_Equipamiento[0].map((elemento, index) => {
                    const titulo = elemento.label
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase()); // Obtiene el título de la sección del acordeón
                    const items = elemento.items; // Obtiene los ítems dentro de la sección

                    return (
                      <Accordion
                        key={index}
                        sx={{
                          boxShadow: "none",
                          "&:before": { display: "none" },
                          backgroundColor: "#181818",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#ffffff" }} />
                          }
                          aria-controls={`panel${index + 1}-content`}
                          id={`panel${index + 1}-header`}
                          sx={{ mb: 0, ml: -2 }} // Anula el margin-bottom para eliminar el separador
                        >
                          <MKTypography
                            variant="h5"
                            sx={{ color: "#FFFFFF", fontWeight: 400 }}
                          >
                            {
                              <h6
                                style={{
                                  color: "#FFFFFF",
                                  fontWeight: 400,
                                  fontSize: "0.9rem",
                                }}
                              >
                                {titulo}
                              </h6>
                            }
                          </MKTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {items.map((item, itemIndex) => (
                            <div
                              key={itemIndex}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <CenteredBlogCardEquip
                                image={item.Imagen}
                                title={item.Item}
                                description="ddd"
                              />
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              </Card>
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#181818",
                  p: 0,
                  mx: { lg: 1 },
                  mb: 2,
                  mt:1,
                  pl: 0,
                }}
              >
                <Box p={0} justifyContent="center" alignItems="center">
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&:before": { display: "none" },
                      backgroundColor: "#181818",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                      sx={{ mb: 0, ml: -2 }} // Anula el margin-bottom para eliminar el separador
                    >
                      <MKTypography
                        variant="h5"
                        sx={{ color: "#FFFFFF", fontWeight: 400 }}
                      >
                        <h3 style={{ color: "#FFD415" }}>Contactanos</h3>
                      </MKTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <form onSubmit={handleSubmit}>
                        <Grid
                          container
                          spacing={2}
                          mt={0}
                          mr={2}
                          justifyContent="center"
                        >
                          {" "}
                          {/* Añadido justifyContent="center" para centrar horizontalmente */}
                          <Grid item xs={12}>
                            <TextField
                              sx={{
                                // Estilos para el contenedor del TextField
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "gray", // Borde cuando no está enfocado
                                  },

                                  "&.Mui-focused fieldset": {
                                    borderColor: "#FFD415", // Borde cuando está enfocado
                                  },
                                },
                                // Estilos para el label
                                "& label": {
                                  color: "gray", // Color del label cuando no está enfocado
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#FFD415", // Color del label cuando está enfocado
                                },
                                // Asegura que el label se comporte bien cuando está enfocado
                                "& label.Mui-focused": {
                                  color: "#FFD415", // Color del label en el estado enfocado
                                  transform:
                                    "translate(14px, -6px) scale(0.75)", // Asegura la posición del label
                                },
                              }}
                              fullWidth
                              label="Nombre y Apellidos"
                              name="nombre"
                              value={formData.nombre}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{
                                // Estilos para el contenedor del TextField
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "gray", // Borde cuando no está enfocado
                                  },

                                  "&.Mui-focused fieldset": {
                                    borderColor: "#FFD415", // Borde cuando está enfocado
                                  },
                                },
                                // Estilos para el label
                                "& label": {
                                  color: "gray", // Color del label cuando no está enfocado
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#FFD415", // Color del label cuando está enfocado
                                },
                                // Asegura que el label se comporte bien cuando está enfocado
                                "& label.Mui-focused": {
                                  color: "#FFD415", // Color del label en el estado enfocado
                                  transform:
                                    "translate(14px, -6px) scale(0.75)", // Asegura la posición del label
                                },
                              }}
                              fullWidth
                              label="Teléfono"
                              name="telefono"
                              value={formData.telefono}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{
                                // Estilos para el contenedor del TextField
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "gray", // Borde cuando no está enfocado
                                  },

                                  "&.Mui-focused fieldset": {
                                    borderColor: "#FFD415", // Borde cuando está enfocado
                                  },
                                },
                                // Estilos para el label
                                "& label": {
                                  color: "gray", // Color del label cuando no está enfocado
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#FFD415", // Color del label cuando está enfocado
                                },
                                // Asegura que el label se comporte bien cuando está enfocado
                                "& label.Mui-focused": {
                                  color: "#FFD415", // Color del label en el estado enfocado
                                  transform:
                                    "translate(14px, -6px) scale(0.75)", // Asegura la posición del label
                                },
                              }}
                              fullWidth
                              label="Email"
                              name="email"
                              type="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              sx={{
                                // Estilos para el contenedor del TextField
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-disabled fieldset": {
                                    borderColor: "gray", // Borde cuando el campo está deshabilitado
                                  },
                                },

                                "& label.Mui-disabled": {
                                  color: "gray", // Color del label cuando el campo está deshabilitado
                                },

                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "gray", // Color del texto cuando el campo está deshabilitado
                                },
                              }}
                              fullWidth
                              disabled
                              label="Modelo"
                              name={producto.Modelo + " " + producto.Version}
                              value={producto.Modelo + " " + producto.Version}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            mt={2}
                            ml={0}
                            sx={{ textAlign: "center" }}
                          >
                            {" "}
                            {/* Añadido textAlign: 'center' para centrar el botón verticalmente */}
                            <Button
                              type="submit"
                              variant="outlined"
                              sx={{
                                width: "100%",
                                borderColor: "#FFD415",
                                color: "#FFD415",
                              }}
                            >
                              Enviar
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Card>
              <Card
                sx={{
                  boxShadow: "none",
                  backgroundColor: "#181818",
                  p: 0,
                  mx: { lg: 1 },
                  mt: 2,
                  pl: 2,
                }}
              >
                <Box p={2}>
                  {//<h2 style={{ color: "#FFFFFF" }}>Calcula tu Cuota</h2>
                  }
                </Box>
              </Card>
            </Grid>
            
            
          </Grid>
        </Box>
        <Box sx={{ p: 4 }}>
          <Card
            sx={{
              boxShadow: "none",
              backgroundColor: "#181818",
              p: 2,
              mx: { lg: 1 },
              mb: 0,
            }}
          >
            <Box p={2}>
             { //<h2 style={{ color: "#FFFFFF" }}>Calcula tu Cuota</h2>
             }
            </Box>
          </Card>
        </Box>
        <MKBox bgColor="info" pt={6} px={1} mt={0}>
          <CenteredFooter />
        </MKBox>
      </MKBox>
    </>
  );
}

export default ProductDetailMobile;
