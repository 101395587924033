import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/bg-renault.webp";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function KodeAuto() {
  const navigate = useNavigate();

  const textsCards = [
    {
      title: "¿Nuevo... Usado?",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Elegir entre un coche nuevo y uno usado depende de tus prioridades.
            Un coche nuevo ofrece garantía, tecnología avanzada y menor riesgo
            de fallos, aunque pueda parecer más caro al principio. Por otro
            lado, un coche usado puede parecer más asequible inicialmente y
            tener una menor depreciación, pero puede tener problemas ocultos y
            menos tecnología, comprometiendo la seguridad. Considera tu
            presupuesto, necesidades y disposición para lidiar con posibles
            reparaciones. La elección ideal debe equilibrar el coste inicial, la
            fiabilidad y las características deseadas.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Dudando,
    },
    {
      title: "Coche Nuevo",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            <strong>Pros:</strong> Garantía completa (cobertura total de
            reparaciones y mantenimiento), última tecnología (ADAS
            obligatorias), historial limpio (sin accidentes ni reparaciones
            previas), financiación favorable (mejores tasas de interés).
          </MKTypography>
          <br />
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            <strong>Contras:</strong> Depreciación rápida (pérdida significativa
            de valor en los primeros años), precio elevado (costo inicial alto).
          </MKTypography>
          <br />
          <h3 style={{ color: "#FFD415" }}>Coche Usado</h3>
          <br />
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            <strong>Pros:</strong> Garantía completa (cobertura total de
            reparaciones y mantenimiento), última tecnología (ADAS
            obligatorias), historial limpio (sin accidentes ni reparaciones
            previas), financiación favorable (mejores tasas de interés).
          </MKTypography>
          <br />
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            <strong>Contras:</strong> Depreciación rápida (pérdida significativa
            de valor en los primeros años), precio elevado (costo inicial alto).
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
    {
      title: "¡Descubre por qué el nuevo Renault Clio es imbatible!",
      subtitle: (
        <>
          <strong>Ventajas Inigualables:</strong>
          <br />
          <ul style={{ paddingTop: "12px" }}>
            <li>
              <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
                <strong>Seguro a Todo Riesgo: </strong>Para conductores noveles
                sin coste adicional durante 3, 4 ó 5 años.
              </MKTypography>
            </li>
            <li>
              <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
                <strong>Mantenimiento Incluido: </strong>Todos los servicios
                programados en la cuota mensual sin coste adicional durante 3, 4
                ó 5 años .
              </MKTypography>
            </li>
            <li>
              <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
                <strong>Costos Claros: </strong>El único gasto adicional es el
                impuesto municipal de circulación.
              </MKTypography>
            </li>
          </ul>
          <br />
          <strong>Sin Contras:</strong>
          <br />
          <ul style={{ paddingTop: "12px" }}>
            <li>
              <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
                <strong>Adquisición sin Entrada Inicial: </strong>
              </MKTypography>
            </li>
            <li>
              <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
                <strong>Depreciación Cubierta:</strong>Valor Mínimo Garantizado{" "}
                <strong>(VMG)</strong> al finalizar el periodo de 3, 4 o 5 años.
              </MKTypography>
            </li>
          </ul>
          <br />
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            ¡No Esperes Más! Visítanos y te asesoraremos en la compra de tu
            nuevo Renault Clio hoy mismo.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: NewCar,
    },
  ];

  const textsCards2 = [
    {
      title: "Costes Ocultos",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Para un conductor novel, los costes ocultos de un coche de ocasión
            pueden ser un desafío abrumador. Reparaciones inesperadas,
            mantenimiento y seguros pueden superar el presupuesto, causando
            estrés. Sin experiencia, es fácil pasar por alto problemas ocultos
            que incrementen el coste total. Un coche nuevo es una opción
            inteligente: costes controlados y garantías incluidas. Asegura una
            experiencia de conducción sin sorpresas desagradables. ¡Invierte en
            tranquilidad y seguridad al volante desde el primer día!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Factura,
    },
    {
      title: "Fiabilidad y Tranquilidad",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Para un conductor novel, la fiabilidad y la tranquilidad son
            esenciales al comprar un coche. La falta de experiencia dificulta
            evaluar un coche de ocasión, llevando a averías frecuentes y altos
            costes de reparación. Sin garantías, la incertidumbre y el estrés
            aumentan. Aunque un coche usado parezca económico, ya sabemos que lo
            barato puede salir caro. Un coche nuevo ofrece todas las garantías y
            ninguna sorpresa desagradable. Invierte en tranquilidad y seguridad
            al volante desde el primer día. ¡Elige sabiamente y conduce sin
            preocupaciones!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Tirada,
    },
    {
      title: "Seguridad Avanzada",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Para un conductor novel, la fiabilidad y la tranquilidad son
            esenciales al comprar un coche. La falta de experiencia dificulta
            evaluar un coche de ocasión, llevando a averías frecuentes y altos
            costes de reparación. Sin garantías, la incertidumbre y el estrés
            aumentan. Aunque un coche usado parezca económico, ya sabemos que lo
            barato puede salir caro. Un coche nuevo ofrece todas las garantías y
            ninguna sorpresa desagradable. Invierte en tranquilidad y seguridad
            al volante desde el primer día. ¡Elige sabiamente y conduce sin
            preocupaciones!
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Accidente,
    },
  ];

  const textsCards3 = [
    {
      title: "Historial",
      title2: "Historial desconocido y Fraude",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los coches usados pueden tener un pasado oculto de accidentes o
            reparaciones deficientes, lo que puede afectar su seguridad. Los
            conductores novatos son más susceptibles a caer en estafas como la
            manipulación del odómetro o la venta de vehículos con problemas
            ocultos, incrementando el riesgo de adquirir un coche inseguro.
            <br />
            <br />
            <strong>La historia de tu nuevo Renault Clio la inicias tu.</strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Historial,
    },
    {
      title: "Kilometraje",
      title2: "Kilometraje alto y Desgaste",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los coches usados suelen tener un kilometraje elevado, lo que indica
            un mayor desgaste de sus componentes. Este desgaste puede llevar a
            la necesidad de costosas reparaciones y mantenimiento constante,
            afectando tanto la seguridad como el presupuesto del conductor
            novato.
            <br />
            <br />
            <strong>
              Estrena un Renault Clio nuevo: kilometraje a cero y sin desgaste.
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Kilometraje,
    },
    {
      title: "Mantenimiento",
      title2: "Inexperiencia en Mantenimiento y Revisiones deficientes",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los conductores novatos pueden no identificar problemas mecánicos a
            tiempo, aumentando el riesgo de accidentes. La falta de experiencia
            en mantenimiento puede acelerar el deterioro del coche y aumentar
            los costos de reparación, dificultando mantener el vehículo en
            buenas condiciones.
            <br />
            <br />
            <strong>
              Tu Clio nuevo te avisa cuando necesita ir al taller. Con la app{" "}
              <q>My Renault</q>, siempre está monitorizado.
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Mantenimiento,
    },
  ];

  const textsCards4 = [
    {
      title: "Averías",
      title2: "Averías frecuentes y Componentes críticos comprometidos",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los coches de segunda mano son más propensos a fallos mecánicos, lo
            que puede ser peligroso y estresante. Sistemas vitales como frenos,
            embrague y airbags pueden estar en mal estado, incrementando el
            riesgo de lesiones graves en caso de accidente.
            <br />
            <br />
            <strong>
              El Renault Clio tiene 3 años de garantía total sin límite de
              kilómetros. ¡Disfruta de tranquilidad total!
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Averia,
    },
    {
      title: "Reparaciones y Seguros",
      title2: "Reparaciones costosas y Seguros mucho más caros",

      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Es probable que un coche usado no cuente con una garantía que cubra
            las averías imprevistas, dejando al conductor novato con la
            responsabilidad de todos los costos de reparación. La letra pequeña
            de las garantías contratadas con las compañías de seguros
            especializadas en coches usados a menudo limita o incluso anula la
            cobertura. Además, un coche de segunda mano puede no ser fiable para
            el uso diario, causando estrés e interrupciones en el aprendizaje y
            la vida cotidiana.
            <br />
            <br />
            <strong>
              Con el Renault Clio, todas las reparaciones están cubiertas por la
              garantía. Los mantenimientos y el seguro a todo riesgo están
              incluidos en la cuota, sin recargos por edad ni por ser conductor
              novel.
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Reparaciones,
    },
    {
      title: "Garantía",
      title2: "Garantía limitada y Baja confiabilidad",

      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Es probable que un coche usado no cuente con una garantía que cubra
            las averías imprevistas, dejando al conductor novato con la
            responsabilidad de todos los costos de reparación. La letra pequeña
            de las garantías contratadas con las compañías de seguros
            especializadas en coches usados a menudo limita o incluso anula la
            cobertura. Además, un coche de segunda mano puede no ser fiable para
            el uso diario, causando estrés e interrupciones en el aprendizaje y
            la vida cotidiana.
            <br />
            <br />
            <strong>
              Puedes confiar plenamente en el Renault Clio. Disfruta de 3 años
              de garantía total sin límite de kilómetros, ampliable hasta 5
              años.
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Garantia,
    },
  ];

  const textsCards5 = [
    {
      title: "Tecnología Obsoleta",
      title2: "Tecnología obsoleta",

      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los modelos más antiguos carecen de las últimas innovaciones en
            materia de seguridad y confort. Esto pone al conductor novato en
            clara desventaja, ya que no puede beneficiarse de tecnologías
            modernas que mejoran la seguridad y la experiencia de conducción.
            <br />
            <br />
            <strong>
              El Renault Clio incorpora de serie los Sistemas Avanzados de Ayuda
              a la Conducción (ADAS), obligatorios desde julio de 2024. Conduce
              con confianza y seguridad.
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Factura,
    },
    {
      title: "Eficiencia Energética",
      title2: "Menor eficiencia energética",

      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los coches más antiguos son mucho menos eficientes en consumo de
            combustible y emisiones, lo que resulta en mayores costos operativos
            y un impacto ambiental negativo. Esto no solo afecta el bolsillo del
            conductor, sino que también incrementa la huella de carbono.
            <br />
            <br />
            <strong>
              La eficiencia energética de Renault está entre las 5 mejores
              marcas con menos emisiones del mundo. ¡Conduce con conciencia
              ecológica!
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Tirada,
    },
    {
      title: "Financiación",
      title2: "Dificultad para obtener financiación y Menor valor de reventa",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Los vehículos usados suelen tener condiciones de financiación menos
            favorables, con tasas de interés más altas y plazos de pago más
            cortos, limitados por su edad. Además, un coche usado pierde valor
            más rápidamente, afectando su precio de reventa y haciendo que la
            inversión inicial sea menos rentable a largo plazo.
            <br />
            <br />
            <strong>
              A través de la financiera del grupo Renault, Mobilize, te
              ofrecemos las mejores condiciones para que adquieras tu Renault
              Clio con total facilidad.
            </strong>
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: Accidente,
    },
  ];

  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      After School
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      ¡Por fin! ¡Ya tienes el carnet! Y ahora... ¡a por la
                      libertad!
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        {/* Cookie Policy Section */}
        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={text.image}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%"
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Card
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.07)",
              padding: "20px",
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <MKBox
              sx={{
                justifyContent: "center",
                display: "flex",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <MKTypography
                variant="h3"
                sx={{ color: "#a8a8a8", textAlign: "center" }}
              >
                Nuestro consejo
              </MKTypography>
              <MKTypography
                variant="h5"
                sx={{ color: "#a8a8a8", textAlign: "center" }}
              >
                Evita los riesgos de un coche de segunda mano.
              </MKTypography>
            </MKBox>
          </Card>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards2.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={text.image}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%"
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Box
          sx={{
            minHeight: "99vh",
            width: "100%",
            backgroundImage: `url(${bgRenault})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
            position: "relative", // Necesario para el pseudo-elemento
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
            },
          }}
        >
          {/* El contenido dentro de la caja */}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid container sx={{ width: "100%" }} spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={4} p={0} sx={{ overflow: "hidden" }}>
                <Box sx={{ width: "100%" }}>
                  <Grid container sx={{ width: "100%" }} spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <MKTypography
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent:  isMobileDevice() ? 'center' : "left",

                          fontFamily: "atomic-regular",
                          letterSpacing: "0.15em",
                          color: "#ffffff",
                          marginTop: "10px", // Ajusta la separación superior según sea necesario
                          transform: "rotate(-2deg)",
                          fontSize: {
                            xs: "2.0rem",
                            sm: "4rem",
                            md: "6rem",
                            lg: "8rem",
                            xl: "2rem",
                          },
                          animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                          textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                        }}
                        variant="h1"
                        align="center"
                        gutterBottom
                      >
                        Renault
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box mt={-5}>
                        <MKTypography
                          sx={{
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#FFD415",
                            marginTop: "-10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "7.5rem",
                              sm: "7.5rem",
                              md: "7.5rem",
                              lg: "8rem",
                              xl: "16rem",
                            },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          Clio
                        </MKTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box mt={-10}>
                        <MKTypography
                          sx={{
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#FFFFFF",
                            marginTop: "-10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "2.5rem",
                              sm: "4rem",
                              md: "6rem",
                              lg: "8rem",
                              xl: "4rem",
                            },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          189,00€ /mes
                        </MKTypography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Container>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "center" }}
            >
              Comprar un coche de segunda mano, especialmente uno de dudosa
              calidad, puede ser una decisión arriesgada para un conductor
              novato. Aunque adquirir un coche usado puede parecer económico, es
              esencial considerar los riesgos asociados con la seguridad y el
              mantenimiento. Para los conductores noveles, invertir en un coche
              nuevo, como el Renault Clio, ofrece mayor seguridad y fiabilidad,
              brindando tranquilidad y protección adicional en la carretera.
              Principales riesgos que se deben considerar al comprar un vehículo
              usado para conductores novatos y razones por las que no se
              recomienda esta práctica:
            </MKTypography>
          </Box>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards3.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={text.image}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%"
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <h4
                          display="inline"
                          style={{
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title2}
                        </h4>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards4.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={text.image}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%"
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <h4
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title2}
                        </h4>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards5.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    >
                      <img
                        src={text.image}
                        alt={"fff"}
                        style={{
                          maxHeight: "250px",
                          width: "100%",
                          borderRadius: "0",
                          objectFit: "cover",
                          objectPosition: "50% 30%"
                        }}
                      />
                    </div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <h4
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title2}
                        </h4>
                        <br />
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container sx={{ width: "100%" }} spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box mt={2}>
                <MKTypography
                  variant="body2"
                  sx={{ color: "#FFFFFF", textAlign: "left" }}
                >
                  Para un conductor novato, los riesgos de un coche de segunda
                  mano de dudosa calidad superan el ahorro inicial. La
                  seguridad, fiabilidad y tranquilidad son esenciales al elegir
                  tu primer coche.
                </MKTypography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <YouTubeCard
                videoId="FzsvkaJPbDo"
                title="No queremos incomodarte. Solo que lo tengas en cuenta"
              />
            </Grid>
          </Grid>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 10,
              sm: 10,
              md: 10,
              lg: 10,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Box mt={2} mb={10}>
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "left" }}
            >
              Evita los riesgos de un coche de segunda mano. ¡Consigue un plan
              financiero personalizado para tu Renault y conduce con
              tranquilidad! ¡Contáctanos, no te arrepentirás!
              <br />
              Recuerda: no comprometas tu seguridad. Compra un Renault nuevo con
              las mejores condiciones del mercado. ¡Descubre tu oferta hoy!
            </MKTypography>
          </Box>
          <Box mt={2}>
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "left" }}
            >
              Comprar un coche nuevo de la marca Renault ofrece múltiples
              ventajas para un conductor novel. A continuación, se detallan las
              principales razones:
              <br />
              <br />
              <ol>
                <li>
                  <strong>Seguridad Avanzada:</strong> Los vehículos Renault
                  están equipados con las últimas tecnologías de seguridad ADAS
                  (Sistemas Avanzados de Asistencia a la Conducción), como la
                  frenada automática de emergencia (AEB) y los asistentes de
                  mantenimiento de carril (LKA), diseñadas para proteger a
                  conductores y pasajeros.
                </li>
                <br />
                <li>
                  <strong>Fiabilidad y Garantía:</strong> Al comprar un coche
                  nuevo de Renault, se obtiene una garantía del fabricante que
                  varía entre 3 y 5 años según el modelo y las promociones. Esto
                  asegura que cualquier problema mecánico o defecto de
                  fabricación esté cubierto, lo cual es crucial para conductores
                  novatos que pueden no estar familiarizados con el
                  mantenimiento y las reparaciones de vehículos.
                </li>
                <br />
                <li>
                  <strong>Tecnología y Conectividad:</strong> Los coches nuevos
                  de Renault vienen equipados con las últimas tecnologías de
                  infoentretenimiento y conectividad, como sistemas de
                  navegación, compatibilidad con smartphones mediante Apple
                  CarPlay y Android Auto, y pantallas táctiles intuitivas. Estas
                  características hacen la experiencia de conducción más
                  agradable y cómoda, especialmente para los jóvenes conductores
                  acostumbrados a la tecnología moderna.
                </li>
                <br />
                <li>
                  <strong>Consumo Eficiente de Combustible:</strong> Renault
                  ofrece vehículos con motores eficientes y económicos en
                  consumo de combustible, lo que reduce los costes operativos y
                  el impacto ambiental.
                </li>
                <br />
                <li>
                  <strong>Facilidad de Conducción:</strong> Muchos modelos de
                  Renault están diseñados para ser fáciles de conducir, con
                  características como transmisiones automáticas suaves,
                  dirección asistida y ayudas de aparcamiento, lo cual reduce el
                  estrés y es ideal para conductores novatos.
                </li>
                <br />
                <li>
                  <strong>Diseño Atractivo y Versatilidad:</strong> Renault
                  ofrece una amplia variedad de modelos, desde coches compactos
                  como el Renault Clio hasta SUV como el Renault Captur,
                  permitiendo a los conductores novatos elegir un vehículo que
                  se adapte a sus necesidades y preferencias.
                </li>
                <br />
                <li>
                  <strong>
                    Asistencia en Carretera y Servicios Postventa:
                  </strong>{" "}
                  Renault proporciona servicios adicionales como asistencia en
                  carretera y paquetes de mantenimiento, que son especialmente
                  útiles para conductores novatos, ofreciendo ayuda en caso de
                  avería y mantenimiento regular del vehículo.
                </li>
                <br />
                <li>
                  <strong>Valor de Reventa:</strong> Los coches nuevos suelen
                  mantener un mejor valor de reventa en comparación con los
                  usados. Un coche nuevo de Renault, bien mantenido, puede tener
                  un buen valor de reventa en el futuro.
                </li>
              </ol>
              <br />
              <br />
              <h3>Ejemplos de Modelos Recomendados:</h3>
              <br />
              <ul>
                <li>
                  Renault Clio: Compacto, económico y altamente equipado con la
                  seguridad y tecnología más avanzadas.
                </li>
                <li>
                  Renault Captur: Un SUV pequeño, versátil y cómodo, ideal para
                  conductores que buscan más espacio y una posición de
                  conducción más alta.
                </li>
                <li>
                  Renault 5 E-Tech: Un coche eléctrico ideal para conductores
                  que buscan una opción ecológica y económica en cuanto a
                  consumo de energía.
                </li>
                <br />
                <br />
                <h3>Ventajas Adicionales con los Sistemas ADAS:</h3>
                <br />
                Desde julio 2024, los coches nuevos de Renault están equipados
                con sistemas ADAS obligatorios que ofrecen diversas ventajas
                para conductores novatos:
              </ul>
              <br />
              <ol>
                <li>
                  Mayor Seguridad en la Conducción: Los sistemas ADAS están
                  diseñados para prevenir accidentes y mitigar sus
                  consecuencias, incluyendo frenada automática de emergencia
                  (AEB), detector activo de cambio de carril (LWD) y alerta de
                  tráfico cruzado (RCTA).
                </li>
                <br />
                <li>
                  Asistencia en la Conducción: Proporcionan asistencia continua
                  al conductor, como el asistente de velocidad inteligente (ISA)
                  y el sistema de detección de somnolencia y distracción (DDR).
                  <br />
                </li>
                <br />
                <li>
                  Mejora de Hábitos de Seguridad: Sistemas como el avisador de
                  cinturón en las plazas traseras (SBR) fomentan el uso del
                  cinturón de seguridad.
                </li>
                <br />
                <li>
                  Reducción de Costes a Largo Plazo: Aunque un coche nuevo con
                  estos sistemas puede ser más costoso inicialmente, los
                  beneficios en términos de seguridad y prevención de accidentes
                  reducen los costes a largo plazo.
                </li>
                <br />

                <li>
                  Mayor Tranquilidad y Confianza: Tener un coche equipado con
                  las últimas tecnologías de seguridad proporciona mayor
                  tranquilidad y confianza al conductor.
                </li>
                <br />
                <li>
                  Preparación para el Futuro: Los coches con sistemas ADAS están
                  alineados con las normativas futuras, garantizando que el
                  vehículo esté actualizado y sea relevante en términos de
                  seguridad durante más tiempo. Mejor Valor de Reventa: Los
                  coches con tecnología avanzada y sistemas de seguridad
                  mantienen mejor su valor en el mercado de segunda mano.
                </li>
              </ol>
              <br />
              <br />
              <h3>Modelo de iniciación más recomendado con Sistemas ADAS:</h3>
              <br />
              <ul>
                <li>
                  Renault Clio: Compacto y equipado con una amplia gama de
                  sistemas ADAS, ideal para la conducción urbana e interurbana.
                </li>
              </ul>
              <br />
              <br />
              En resumen, un coche nuevo de Renault equipado con los sistemas
              ADAS ofrece una combinación ideal de seguridad, asistencia en la
              conducción, mejora de hábitos de seguridad, reducción de costes a
              largo plazo, mayor tranquilidad y confianza, preparación para el
              futuro y mejor valor de reventa. Estas ventajas son
              particularmente beneficiosas para un conductor novato,
              proporcionando una experiencia de conducción mucho más segura y
              agradable.
              <br />
              <br />
              <br />
              <h1
                style={{
                  position: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  fontSize: "650%",
                }}
              >
                ADAS
              </h1>
              <h6
                style={{
                  position: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  fontSize: "150%",
                }}
              >
                Advanced Driver Assistance Systems
              </h6>
              <br />
              <br />A partir del 6 de julio de 2024, todos los coches nuevos
              matriculados en Europa deberán incluir obligatoriamente estos 10
              sistemas avanzados de asistencia a la conducción (ADAS) para
              mejorar la seguridad vial. Estos sistemas son:
              <ol>
                <br />
                <li>
                  <strong>Frenada automática de emergencia (AEB):</strong>{" "}
                  Reduce automáticamente la velocidad o frena por completo el
                  vehículo para evitar colisiones.
                </li>
                <br />
                <li>
                  <strong>Detector activo de cambio de carril (LWD):</strong>{" "}
                  Ayuda a mantener el coche dentro del carril y evita salidas
                  involuntarias.
                </li>
                <br />
                <li>
                  <strong>Alerta de tráfico cruzado (RCTA):</strong> Advierte
                  sobre vehículos, personas u objetos al salir marcha atrás.
                </li>
                <br />
                <li>
                  <strong>Asistente de velocidad inteligente (ISA):</strong>{" "}
                  Adapta la velocidad del coche según los límites legales de la
                  vía.
                </li>
                <br />
                <li>
                  <strong>
                    Sistema de detección de somnolencia y distracción (DDR):
                  </strong>{" "}
                  Monitoriza al conductor y lanza alertas si detecta fatiga o
                  distracción.
                </li>
                <br />

                <li>
                  <strong>
                    Avisador de cinturón en las plazas traseras (SBR):
                  </strong>{" "}
                  Notifica si algún ocupante trasero no lleva puesto el cinturón
                  de seguridad.
                </li>
                <br />

                <li>
                  <strong>Señal de frenada de emergencia (ESS):</strong>{" "}
                  Parpadea las luces de freno en caso de una frenada brusca.
                </li>
                <br />

                <li>
                  <strong>
                    Preinstalación de alcoholímetro anti arranque:
                  </strong>{" "}
                  Facilita la instalación de sistemas que impiden arrancar el
                  coche si el conductor ha bebido.
                </li>
                <br />

                <li>
                  <strong>Registrador de eventos de datos (EDR):</strong> Graba
                  información básica del coche antes y después de un accidente.
                </li>
                <br />

                <li>
                  <strong>
                    Control de la presión de los neumáticos (TPMS):
                  </strong>{" "}
                  Asegura que los neumáticos mantengan la presión adecuada.
                </li>
                <br />
                <br />
              </ol>
              Estos requisitos, vigentes desde julio de 2022 para vehículos de
              nueva homologación, ahora se extienden a todos los coches nuevos
              matriculados. Según la Comisión Europea, la implementación de
              estos ADAS podría evitar más de 25,000 muertes y 140,000 heridos
              graves entre 2022 y 2038. La DGT estima que en España reducirían
              la gravedad de los accidentes en un 57% y podrían evitar más de
              50,000 siniestros.
              <br />
              <br />
              <strong>
                Pero si aún no te hemos convencido de comprarte un coche nuevo,
                aquí tienes una Guía Completa para Comprar un Coche de Segunda
                Mano.
              </strong>
              <br />
              <strong>Introducción:</strong> Esta guía ofrece consejos para
              evitar inconvenientes al comprar un coche usado, dado que es una
              decisión financiera significativa que debe ser abordada con
              cuidado.
              <br />
              <br />
              <br />
              <h3>Aspectos Importantes a Considerar:</h3>
              <br />
              <ol>
                <li>
                  <strong> Presupuesto:</strong>
                  <ul>
                    <li> Determina tu presupuesto total.</li>
                    <li>
                      Incluye los costes adicionales (seguros, mantenimiento,
                      impuestos).
                    </li>
                    <li>
                      Mantente dentro del presupuesto. Lo ideal sería que los
                      pagos del coche no excedan el 20% de los ingresos netos.
                    </li>
                    <li>
                      Considera los costes de las reparaciones en el precio. En
                      un coche usado pueden ser importantes.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>
                    {" "}
                    Historial de mantenimiento y registro de servicio:
                  </strong>
                  <ul>
                    <li>Verifica exhaustivamente el historial del coche.</li>
                    <li>
                      Conversa con el vendedor sobre registros de siniestros,
                      financiaciones pendientes o denuncias de robo.
                    </li>
                    <li>Valida la autenticidad de los documentos.</li>
                    <li>
                      Utiliza servicios en línea para obtener informes
                      detallados. Consigue un informe de situación de la DGT
                      (Dirección General de Tráfico).
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>Kilometraje del vehículo:</strong>
                  <ul>
                    <li>
                      Asegúrate de que el kilometraje concuerde con lo
                      publicitado. Hay una serie de detalles fáciles de valorar
                      que pueden indicar que el cuentakilómetros ha sido
                      manipulado (desgaste del volante, del pomo del cambio, de
                      los pedales, etc.).
                    </li>
                    <li>
                      Un coche poco utilizado puede necesitar más reparaciones
                      debido al envejecimiento prematuro por falta de uso de las
                      partes de plástico y goma.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong> Investigación previa del modelo y marca:</strong>
                  <ul>
                    <li>Investiga diferentes modelos y marcas.</li>
                    <li>
                      Conoce la fiabilidad del vehículo, problemas comunes y
                      feedback de otros propietarios. Estar al tanto de posibles
                      retiradas del mercado o fallos recurrentes.
                    </li>
                    <li>
                      Consulta reseñas y valoraciones de usuarios y expertos.
                      Hoy en día internet está plagado de foros que te pueden
                      ayudar a comprender cuáles son los problemas más
                      habituales del coche que estás planteando comprar.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>Homologaciones del vehículo de segunda mano:</strong>
                  <ul>
                    <li>
                      {" "}
                      Verifica que cualquier modificación incorporada al
                      vehículo esté homologada y cumpla con las normativas de
                      tránsito.
                    </li>
                    <li>
                      {" "}
                      Específicamente, chequea faros, neumáticos, llantas y
                      sistema de escape. A la hora de pasar la ITV, si tu Ficha
                      Técnica no dispone de las homologaciones pertinentes
                      tendrás un grave problema.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong> Factores estéticos y mecánicos:</strong>
                  <ul>
                    <li>
                      Inspecciona el exterior del coche por posibles
                      modificaciones.
                    </li>
                    <li>Revisa el estado exterior e interior del coche.</li>
                    <li>
                      Verifica las medidas y el código de velocidad de los
                      neumáticos y compáralo con la Ficha Técnica. Asegurate de
                      que los 4 sean iguales.
                    </li>
                    <li>
                      Comprueba el motor, el estado de los neumáticos, frenos y
                      embrague, y otros componentes importantes como la
                      distribución.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong> Inspección por un mecánico de confianza:</strong>
                  <ul>
                    <li>
                      Realiza una revisión técnica detallada. Verifica posibles
                      cambios o deterioros (por accidentes) en la estructura del
                      bastidor.
                    </li>
                    <li>
                      Analiza todos los aspectos del motor y posibles fugas de
                      fluidos. Verifica el estado de los silentblocks, juntas,
                      plásticos y gomas.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>Informe de historial del vehículo:</strong>
                  <ul>
                    <li>
                      Revisa accidentes, cambios de propietario o problemas
                      legales.
                    </li>
                    <li>
                      Dialoga con el vendedor y haz preguntas específicas que
                      puedan ponerlo en evidencia.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>Prueba de manejo exhaustiva:</strong>

                  <ul>
                    <li>
                      Realiza una prueba de conducción para evaluar el
                      rendimiento, especialmente el embrague y la aceleración
                      desde muy bajas revoluciones. Verifica que los cambios de
                      marcha se produzcan correctamente.
                    </li>
                    <li>
                      Evalúa el rendimiento del vehículo en diversas
                      situaciones. Subir una rampa pronunciada de un parking,
                      por ejemplo.
                    </li>
                    <li>
                      Escucha ruidos extraños y verifica el funcionamiento de
                      todos los sistemas del coche.
                    </li>
                    Observa sonidos extraños, respuesta de frenos (haz una
                    frenada fuerte y a fondo en un sitio donde puedas efectuar
                    la maniobra sin poner en peligro a nadie, un polígono, por
                    ejemplo), y asegúrate del buen funcionamiento de todos los
                    componentes
                    <li>electrónicos.</li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong> Negociación:</strong>
                  <ul>
                    <li>
                      Negocia el precio basándote en la investigación que has
                      hecho y el estado real del coche.
                    </li>
                    <li>
                      Considera obtener una garantía o un contrato de servicio
                      sin letra pequeña.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>
                    Documentación legal y transferencia de título:
                  </strong>
                  <ul>
                    <li>
                      Asegúrate de tener todos los documentos necesarios (título
                      de propiedad, historial de mantenimiento, informes de
                      inspección).
                    </li>
                    <li>
                      Confirma que el vendedor es realmente el dueño del
                      vehículo verificando su identidad.
                    </li>
                    <li>
                      Actualiza la titularidad post-compra siguiendo las
                      normativas locales, y hazlo rápido para evitar sorpresas
                      de última hora.
                    </li>
                    <li>
                      Asegúrate y verifica que no haya gravámenes o multas
                      pendientes.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>Garantías y política de devolución:</strong>
                  <ul>
                    <li>Investiga sobre garantías extendidas.</li>
                    <li>
                      Infórmate bien sobre las condiciones de estas antes de
                      concretar la compra.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <strong>Compra Final:</strong>
                  <ul>
                    <li>Realiza el pago de forma segura. Nunca en efectivo.</li>
                    <li>
                      Registra el coche a tu nombre y asegura el vehículo.
                    </li>
                  </ul>
                  <br />
                </li>
                Conclusión: Comprar un coche de segunda mano puede ser una
                excelente manera de obtener un vehículo a un precio más
                asequible, siempre y cuando se haga con precaución y estando
                bien informado. Seguir esta guía te posicionará mejor para hacer
                una compra inteligente y segura.
              </ol>
              <br />
            </MKTypography>
          </Box>
        </Container>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodeAuto;
