import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/bg-presentation-renault-x.jpg";
import bgRenault from "assets/images/bg-renault.webp";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function KodeTerms() {
  const navigate = useNavigate();
  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), #181818), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      Términos y Condiciones
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    ></MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        {/* Cookie Policy Section */}
        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
            color:'#ffffff'
          }}
        >
          <p>
            Al acceder a Kode Cars Market, aceptas los siguientes términos y
            condiciones. Si no estás de acuerdo con alguno de ellos, te pedimos
            que no utilices nuestro sitio.
          </p>

          <h2>1. Uso del sitio</h2>
          <p>
            El contenido de este sitio web es únicamente informativo. Mostramos
            el stock disponible de vehículos Renault para facilitar tu consulta.
            La venta de los vehículos no se realiza directamente a través de
            este sitio web, sino que gestionamos el contacto y la venta con
            Renault.
          </p>

          <h2>2. Intermediación en la venta</h2>
          <p>
            Actuamos como intermediarios entre los usuarios interesados y
            Renault. La compra final se realiza directamente con Renault, quien
            es responsable de los precios, las condiciones de venta y la entrega
            del vehículo.
          </p>

          <h2>3. Precios y disponibilidad</h2>
          <p>
            Los precios mostrados en nuestro sitio web son aproximados y pueden
            estar sujetos a variaciones. La confirmación de precios finales y
            disponibilidad se realiza durante el proceso de gestión, tras
            contactar con nosotros y Renault.
          </p>

          <h2>4. Contacto para completar la venta</h2>
          <p>
            Para formalizar una venta o gestionar una consulta sobre un
            vehículo, es necesario que contactes con nuestro equipo de soporte.
            Nosotros facilitaremos el proceso con Renault, pero no somos
            responsables de la venta directa ni de las condiciones comerciales.
          </p>

          <h2>5. Limitación de responsabilidad</h2>
          <p>
            No nos hacemos responsables por errores en la información, cambios
            de precios o la disponibilidad de los vehículos que aparecen en este
            sitio. Las transacciones finales y cualquier asunto legal derivado
            de la compra del vehículo serán gestionados directamente por
            Renault.
          </p>
        </Container>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default KodeTerms;
