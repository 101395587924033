import React, { useState } from "react";
import { Box, Grid, Card, CardMedia, Typography } from "@mui/material";
import { styled } from "@mui/system";
import etc from "assets/images/etc.png";
import et0 from "assets/images/et0.png";
import eteco from "assets/images/eteco.png";
import MKTypography from "components/MKTypography";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
  {
    src: et0,
    alt: "Etiqueta 0",
    text: "Tendrán derecho a la obtención del distintivo ambiental Cero Emisiones los vehículos registrados como eléctricos de batería (BEV), eléctricos de autonomía extendida (REEV), híbridos enchufables (PHEV) con una autonomía mínima de 40 km, o vehículos de pila de combustible.",
  },
  {
    src: eteco,
    alt: "Etiqueta ECO",
    text: "Tendrán derecho al distintivo ambiental ECO los turismos y comerciales ligeros híbridos enchufables con menos de 40 km de autonomía, híbridos no enchufables (HEV) o vehículos de gas natural (GNC o GLP). Los de gasolina deben cumplir las normativas EURO 4, 5 o 6, y los diésel la EURO 6. También lo obtendrán los vehículos de más de 8 plazas y de transporte de mercancías híbridos o de gas que cumplan la norma EURO 6. Los vehículos ligeros (categoría L) deben ser híbridos enchufables con autonomía menor a 40 km o híbridos no enchufables.",
  },
  {
    src: etc,
    alt: "Etiqueta C",
    text: "Tendrán derecho a la obtención del distintivo ambiental C (Verde) los turismos y comerciales ligeros registrados que cumplan con las normativas de emisiones gasolina EURO 4, 5 o 6, y diésel EURO 6. También podrán obtenerlo los vehículos de más de 8 plazas y de transporte de mercancías con nivel de emisiones EURO 6, independientemente del tipo de combustible. Los vehículos ligeros (categoría L) deberán cumplir con las normativas de emisiones EURO 3 o 4.",
  },
];

const ImageCard = styled(Card)(({ theme, hoverEffect }) => ({
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.3s ease, opacity 0.3s ease, width 0.3s ease, height 0.3s ease",
  backgroundColor: "transparent",
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: hoverEffect ? "400px" : "300px",  // Aumenta el tamaño al hacer hover
  maxHeight: hoverEffect ? "auto" : "300px",  // Aumenta el tamaño dinámicamente para mostrar el texto
  opacity: hoverEffect ? 0.9 : 1, // Ajusta la opacidad en hover
}));

const HoverText = styled(Typography)(({ theme, hoverEffect }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  minHeight: "100%",
  height: "auto",  
  backgroundColor: "#181818",
  //padding: "16px",
  color: "white",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  opacity: hoverEffect ? 1 : 0,
  transition: "opacity 0.3s ease",
  pointerEvents: hoverEffect ? "auto" : "none",
  boxSizing: "border-box",
}));



const HoverEffectGrid = () => {
  const [hovered, setHovered] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  const carouselSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: false,
  };

  return (
    <Box sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ width: { xs: "100%", sm: "90%", lg: "80%" }, mx: "auto" }}>
        <Box mb={5} lineHeight={1}>
          <MKTypography color="white" variant="h3" align="center" gutterBottom>
            DISTINTIVOS AMBIENTALES
          </MKTypography>
          <MKTypography
            sx={{ color: "#ffffff" }}
            variant="body2"
            align="center"
          >
            En España, los vehículos se clasifican según su nivel de emisiones para reducir la contaminación y mejorar la calidad del aire. Este sistema de etiquetado medioambiental distingue a los coches por su impacto ecológico, asignándoles una etiqueta que otorga beneficios como el acceso a zonas de bajas emisiones o ventajas fiscales. Conocer estos distintivos es clave para tomar decisiones sostenibles e informadas al elegir tu vehículo.{" "}
          </MKTypography>
        </Box>

        {isMobile ? (
          <Slider {...carouselSettings}>
            {images.map((image, index) => (
              <Box
                p={2}
                sx={{
                  textAlign: "center",
                  py: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative", // Esto asegura que el número grande se posicione relativo a cada slide
                }}
              >
                <ImageCard
                  hoverEffect={hovered !== null && hovered !== index}
                  onMouseEnter={() => setHovered(index)}
                  onMouseLeave={() => setHovered(null)}
                  sx={{
                    maxWidth: "100%",
                    width: "100%",
                    maxHeight: "100%",
                    textAlign: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      marginTop: "0px",
                      height: hovered === index ? "400px" : '300px',
                      width: "300px",
                      objectFit: "cover",
                    }}
                    image={image.src}
                    alt={image.alt}
                  />
                  <HoverText hoverEffect={hovered === index}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#ffffff", fontWeight: 600 }}
                      mt={2}
                      mb={1}
                    >
                      {image.alt}
                    </Typography>
                    <Typography variant="body2">{image.text}</Typography>
                  </HoverText>
                </ImageCard>
              </Box>
            ))}
          </Slider>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {" "}
            {/* Centrando las imágenes */}
            {images.map((image, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // Centrando cada Grid item
                }}
              >
                <ImageCard
                  hoverEffect={hovered !== null && hovered !== index}
                  onMouseEnter={() => setHovered(index)}
                  onMouseLeave={() => setHovered(null)}
                  sx={{
                    maxWidth: "100%",
                    width: "100%",
                    maxHeight: "100%",
                    textAlign: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      marginTop: "0px",
                      height: "300px",
                      width: "300px",
                      objectFit: "cover",
                    }}
                    image={image.src}
                    alt={image.alt}
                  />
                  <HoverText hoverEffect={hovered === index}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#ffffff", fontWeight: 600 }}
                      mt={2}
                      mb={1}
                    >
                      {image.alt}
                    </Typography>
                    <Typography variant="body2">{image.text}</Typography>
                  </HoverText>
                </ImageCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default HoverEffectGrid;
