import * as React from "react";
import { Suspense, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import { Link as MuiLink } from "@mui/material";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "pages/Presentation/sections/NavbarTest";
import bgImage from "assets/images/renault-5.webp";
import bgRenault from "assets/images/bg-renault.webp";
import bgRenault2 from "assets/images/Renault-Scenic.jpg";
import backets from "assets/images/backets.webp";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import Dudando from "assets/images/Dudando.webp";
import PadreHijo from "assets/images/Padre HIjo.webp";
import NewCar from "assets/images/New Car.webp";

import Historial from "assets/images/Historia.webp";
import Kilometraje from "assets/images/Kilometraje.webp";
import Mantenimiento from "assets/images/Mantenimiento.webp";

import Accidente from "assets/images/Accidente.webp";
import Factura from "assets/images/Factura.webp";
import Tirada from "assets/images/Tirada.webp";

import Averia from "assets/images/Averias.webp";
import Reparaciones from "assets/images/Seguro.webp";
import Garantia from "assets/images/Garantia.webp";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CenteredFooter from "examples/Footers/CenteredFooter";
import YouTubeCard from "pages/Presentation/sections/YouTube";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Carousel from "./slider";
function KodePreference() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const handleToggleAccordion = () => {
    setExpanded(!expanded);
  };
  const handleCloseAccordion = () => {
    setExpanded(false);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));
  const textsCards = [
    {
      title: "Sin entrada mínima",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Compra tu coche sin necesidad de un importe mínimo de entrada,
            adaptándonos a tu presupuesto y facilitándote las mejores
            condiciones posibles.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: "Dudando",
    },
    {
      title: "Adaptado a tu presupuesto",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Disfruta de cuotas mensuales muy competitivas y ajustadas a tus
            necesidades. Podrás conducir tu coche nuevo sin preocuparte por
            cuotas elevadas.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
    {
      title: "Al final del contrato tú decides",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Devolver el vehículo sin más obligaciones, quedártelo, o cambiarlo
            por uno nuevo con condiciones muy ventajosas.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: NewCar,
    },
  ];
  const textsCards2 = [
    {
      title: "1: Cambiar tu coche por uno nuevo",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Para disfrutar de un nuevo Renault entrega el tuyo y cámbialo por
            otro nuevo. Disfrutarás de una oferta exclusiva en la renovación de
            tu Renault sin comisión de apertura, y con descuentos aún más
            atractivos, diseñados especialmente para ti.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: "Dudando",
    },
    {
      title: "2: Quedarte tu Renault",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Para continuar disfrutando de tu vehículo, solo tienes que pagar (o
            financiar) la última cuota del contrato suscrito con Renault
            Preference y será tuyo. Si lo financias, además, disfrutarás de unas
            condiciones muy atractivas que Mobilize Financial Services reserva
            especialmente para ti.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
    {
      title: "3: Devolver el coche",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Si finalmente eliges devolver el coche, también es muy sencillo.
            Simplemente tienes que entregarlo como pago de la última cuota de tu
            contrato con Renault Preference.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: NewCar,
    },
  ];
  const textsCards3 = [
    {
      title: "Mantenimiento y garantía",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Cubre todas las operaciones recogidas en las fichas de mantenimiento
            y garantía de acuerdo con las recomendaciones del fabricante. Los
            mantenimientos y las revisiones se pasarán en la{" "}
            <a
              style={{ color: "#FFD415" }}
              href="https://www.renault.es/concesionarios.html"
            >
              Red Oficial Renault
            </a>{" "}
            y los costes ya están incluidos en el importe de las cuotas.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: "Dudando",
    },
    {
      title: "Taller Puerta a Puerta",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Cubre los riesgos propios originados por la conducción en caso de
            tener un accidente. Si realizas las reparaciones en la{" "}
            <a
              style={{ color: "#FFD415" }}
              href="https://www.renault.es/concesionarios.html"
            >
              Red Oficial Renault
            </a>
            , disfrutarás de un 50% de descuento en el importe de la franquicia.
            Es decir la franquicia quedará reducida a sólo 300€. (2)
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: PadreHijo,
    },
    {
      title: "3: Devolver el coche",
      subtitle: (
        <>
          <MKTypography variant="body2" sx={{ color: "#FFFFFF" }}>
            Este servicio te ofrece la posibilidad que un chófer o grúa recoja
            tu vehículo para entregarlo en el taller y devolverlo al destino
            elegido por ti, una vez efectuada la revisión de mantenimiento. Con
            una sola llamada podrás concertar el servicio de recogida y entrega,
            además de la cita con el taller.
          </MKTypography>
        </>
      ),
      color: "#181818",
      image: NewCar,
    },
  ];

  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const reservationDrawer = () => {
    setIsOpenReservation(!isOpenReservation);
  };

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const manejarCambio = (nuevoValor) => {
    setValorHijo(nuevoValor);
    //console.log(nuevoValor);
  };

  /*const isMobileDevice = () => {
  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };*/
  

  return (
    <>
      <Box sx={{ backgroundColor: "#181818", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          {
            <ResponsiveAppBar
              reservationDrawer={reservationDrawer}
              onCambio={manejarCambio}
            />
          }
        </Box>
        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
            position: "relative", // Necesario para el pseudo-elemento
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 00%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 70%)
      `,
            },
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid
                sx={{
                  marginTop: {
                    xs: `${appBarHeight * 2}px`,
                    sm: `${appBarHeight * 2}px`,
                    md: `${appBarHeight * 2}px`,
                    lg: `${appBarHeight}px`,
                  },
                }}
                container
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MKBox sx={{ maxWidth: "100%" }}>
                    <MKTypography
                      color="white"
                      variant="h1"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1,
                        fontSize: {
                          xs: "2.5rem",
                          sm: "3rem",
                          md: "4rem",
                          lg: "5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      RENAULT PREFRENCE
                    </MKTypography>
                    <MKTypography
                      color="white"
                      variant="h3"
                      sx={{
                        fontFamily: "Rodina-Regular",
                        textAlign: "center",
                        lineHeight: 1.5,
                        fontSize: {
                          xs: "1.5rem",
                          sm: "2rem",
                          md: "2rem",
                          lg: "2.5rem",
                        },
                        textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                      }}
                    >
                      Compra tu nuevo Renault con la mejor opción finaciera
                     
                    </MKTypography>
                  </MKBox>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>
        {/* Cookie Policy Section */}
        <Container
          sx={{
            mt: {
              xs: -2,
              sm: -2,
              md: -2,
              lg: -14,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box
          sx={{
            minHeight: "99vh",
            width: "100%",
            backgroundImage: `url(${bgRenault})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
            position: "relative", // Necesario para el pseudo-elemento
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
            },
          }}
        >
          {/* El contenido dentro de la caja */}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid container sx={{ width: "80%" }} spacing={2}>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={4}></Grid>
              <Grid item xs={12} lg={4} p={0} sx={{ overflow: "hidden" }}>
                <Box sx={{ width: "100%" }}>
                  <Grid container sx={{ width: "100%" }} spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <MKTypography
                        sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          fontFamily: "atomic-regular",
                          letterSpacing: "0.15em",
                          color: "#ffffff",
                          marginTop: "10px", // Ajusta la separación superior según sea necesario
                          transform: "rotate(-2deg)",
                          fontSize: {
                            xs: "3.5rem",
                            sm: "4rem",
                            md: "6rem",
                            lg: "8rem",
                            xl: "2rem",
                          },
                          animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                          textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                        }}
                        variant="h1"
                        align="center"
                        gutterBottom
                      >
                        Renault
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box mt={-3}>
                        <MKTypography
                          sx={{
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#FFD415",
                            marginTop: "-10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "3.5rem",
                              sm: "4rem",
                              md: "6rem",
                              lg: "8rem",
                              xl: "4rem",
                            },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          Preference
                        </MKTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Box mt={-4}>
                        <MKTypography
                          sx={{
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#FFFFFF",
                            marginTop: "-10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "3.5rem",
                              sm: "4rem",
                              md: "6rem",
                              lg: "8rem",
                              xl: "1rem",
                            },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          La solución
                        </MKTypography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12} p={1}>
                      <Box mt={-0}>
                        <MKTypography
                          variant="body2"
                          sx={{ color: "#FFFFFF", textAlign: "justify" }}
                        >
                          Descubre{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://www.renault.es/renault-financiacion/renault-preference.html"
                          >
                            Renault Preference
                          </a>
                          , una solución diseñada por{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://mobilize-fs.es/"
                          >
                            MFS (Mobilize Financial Services){" "}
                          </a>
                          la entidad financiera de{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://www.renaultgroup.com/en/"
                          >
                            Renault Group
                          </a>
                          , que te permite cambiar de coche cada 3, 4 o 5 años.
                          <br />
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://www.renault.es/renault-financiacion/renault-preference.html"
                          >
                            Renault Preference
                          </a>{" "}
                          te ofrece unas condiciones de financiación totalmente
                          adaptadas a tus necesidades.{" "}
                          <strong>¡Es la opción perfecta!</strong>. Es un
                          producto financiero muy sencillo: elige el{" "}
                          <a
                            style={{ color: "#FFD415" }}
                            href="https://www.renault.es/"
                          >
                            Renault{" "}
                          </a>
                          que más te guste y escoge la duración del contrato: 3,
                          4 o 5 años (1)
                        </MKTypography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Container>
          <MKBox mt={4}>
            <MKTypography
              sx={{
                //fontFamily: "Rodina-Regular",
                //letterSpacing: "0.15em",
                color: "#FFFFFF",
                //transform: "rotate(-2deg)",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "4rem",
                  xl: "3rem",
                },
                animation: "blink-animation 0.2s 3 1s", // Agrega la animación
              }}
              variant="h6"
              align="center"
              gutterBottom
            >
              VERSATILIDAD
            </MKTypography>
          </MKBox>
          <MKBox mt={-2}>
            <MKTypography
              sx={{
                color: "#FFFFFF",
              }}
              variant="h5"
              align="center"
              gutterBottom
            >
              Al finalizar tu contrato tienes 3 opciones para elegir
            </MKTypography>
          </MKBox>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards2.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Box sx={{ marginTop: "10px" }}>
          <Box
            mt={4}
            sx={{
              minHeight: "69vh",
              width: "100%",
              backgroundImage: `url(${bgRenault2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: `${appBarHeight}px`,
              position: "relative", // Necesario para el pseudo-elemento
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
              },
            }}
          >
            {/* El contenido dentro de la caja */}
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid container sx={{ width: "80%" }} spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Box sx={{ width: "100%" }}>
                    <Grid container sx={{ width: "100%" }} spacing={2}>
                      <Grid item xs={12} lg={12}>
                        <MKTypography
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#ffffff",
                            marginTop: "10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "3.5rem",
                              sm: "4rem",
                              md: "6rem",
                              lg: "8rem",
                              xl: "2rem",
                            },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          Renault
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-3}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFD415",
                              marginTop: "-10px", // Ajusta la separación superior según sea necesario
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "3.5rem",
                                sm: "4rem",
                                md: "6rem",
                                lg: "8rem",
                                xl: "4rem",
                              },
                              animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            Preference
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-4}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFFFFF",
                              marginTop: "-10px", // Ajusta la separación superior según sea necesario
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "3.5rem",
                                sm: "4rem",
                                md: "6rem",
                                lg: "8rem",
                                xl: "1rem",
                              },
                              animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            La solución
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12} p={1}>
                        <Box mt={-0}>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#FFFFFF", textAlign: "justify" }}
                          >
                            Descubre{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/renault-financiacion/renault-preference.html"
                            >
                              Renault Preference
                            </a>
                            , una solución diseñada por{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://mobilize-fs.es/"
                            >
                              MFS (Mobilize Financial Services){" "}
                            </a>
                            la entidad financiera de{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renaultgroup.com/en/"
                            >
                              Renault Group
                            </a>
                            , que te permite cambiar de coche cada 3, 4 o 5
                            años.
                            <br />
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/renault-financiacion/renault-preference.html"
                            >
                              Renault Preference
                            </a>{" "}
                            te ofrece unas condiciones de financiación
                            totalmente adaptadas a tus necesidades.{" "}
                            <strong>¡Es la opción perfecta!</strong>. Es un
                            producto financiero muy sencillo: elige el{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/"
                            >
                              Renault{" "}
                            </a>
                            que más te guste y escoge la duración del contrato:
                            3, 4 o 5 años (1)
                          </MKTypography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4}></Grid>
                <Grid item xs={12} lg={4} p={0}>
                  {
                    <Carousel
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  }
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Container>
          <MKBox mt={15}>
            <MKTypography
              sx={{
                //fontFamily: "Rodina-Regular",
                //letterSpacing: "0.15em",
                color: "#FFFFFF",
                //transform: "rotate(-2deg)",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "4rem",
                  xl: "3rem",
                },
                animation: "blink-animation 0.2s 3 1s", // Agrega la animación
              }}
              variant="h6"
              align="center"
              gutterBottom
            >
              COMODIDAD
            </MKTypography>
          </MKBox>
          <MKBox mt={-2}>
            <MKTypography
              sx={{
                color: "#FFFFFF",
              }}
              variant="h5"
              align="center"
              gutterBottom
            >
              Servicios (opcionales) que desde <strong>Kode Cars Market</strong>{" "}
              siempre incluimos en la cuota:
            </MKTypography>
          </MKBox>
        </Container>
        <Container
          sx={{
            mt: {
              xs: 6,
              sm: 6,
              md: 6,
              lg: 6,
            },
            mb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {textsCards3.map((text, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
                  <MKBox
                    position="relative"
                    mx={0}
                    mt={0}
                    mb={0}
                    overflow="hidden"
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      }}
                    ></div>
                  </MKBox>
                  <MKBox p={2} mt={0} textAlign="center" alignItems="center">
                    <MKBox
                      mt={0}
                      mb={3}
                      mx={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MKBox flex={1} sx={{ textAlign: "left" }}>
                        <h2
                          display="inline"
                          variant="h4"
                          style={{
                            color: "#FFD415",
                            textTransform: "capitalize",
                            fontWeight: "bold",
                          }}
                        >
                          {text.title}
                        </h2>
                        <br />
                        <MKBox>
                          <MKTypography
                            sx={{ color: "#ffffff", textAlign: "justify" }}
                          >
                            {text.subtitle}
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Card>
              </Grid>
            ))}
          </Grid>
         
        </Container>
        <Container>
          <MKBox mt={15}>
            <MKTypography
              sx={{
                //fontFamily: "Rodina-Regular",
                //letterSpacing: "0.15em",
                color: "#FFFFFF",
                //transform: "rotate(-2deg)",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "3rem",
                  lg: "4rem",
                  xl: "3rem",
                },
                animation: "blink-animation 0.2s 3 1s", // Agrega la animación
              }}
              variant="h6"
              align="center"
              gutterBottom
            >
              NUESTRA RECOMENDACIÓN
            </MKTypography>
          </MKBox>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ color: "#FFFFFF", textAlign: "justify" }}
            >
              Desde nuestra amplia experiencia,{" "}
              <strong>Kode Cars Market</strong> considera que la mejor opción -y
              la más económica- es la propuesta{" "}
              <strong>PREFERENCE A 37 MESES</strong>. Es decir, cambiar de coche
              cada 3 años. (el mes 37 es el mes de intercambio entre el nuevo
              coche y el actual).
              <br /> <br />
              ¿Porqué?: Básicamente porque la cuota mensual es más económica que
              si fuera a 4 ó 5 años debido, sobre todo, al valor residual
              asignado por la marca al vehículo para cada periodo anual.
              <br /> <br />
              En el momento de cambiar el vehículo por otro nuevo,{" "}
              <a style={{ color: "#FFD415" }} href="https://www.renault.es/">
                Renault{" "}
              </a>{" "}
              premia tu fidelidad eliminando las comisiones de apertura y
              estudio <strong>(-3,1%)</strong> a la vez que ofrece un descuento
              adicional <strong>(+2%)</strong>. Es decir, que de entrada
              dispondrás de un <strong>5,1%</strong> de descuento adicional
              respecto del primer coche adquirido.
              <br />
              <br />
              Estas ventajosas condiciones hacen que la cuota del nuevo coche
              -aún siendo el mismo- sea bastante más económica que la que se
              estaba pagando hasta ese momento por el coche actual. Por lo
              tanto, cuanto antes lo cambies, antes disfrutarás de las mejores
              condiciones posibles.
              <br />
              <br />
              Además, como el coche dispone -por ley- de una{" "}
              <strong>
                garantía total e ilimitada durante los 3 años de vigencia del
                contrato
              </strong>
              , no hay que prever ningún tipo de gasto adicional en caso de que
              el coche tuviera cualquier tipo de avería, por grave que esta
              fuera, cosa que sí sucedería si optaras por la solución de 4 ó 5
              años.
              <br />
              <br />
              Esto no quiere decir que, en casos muy puntuales, las opciones de
              4 ó 5 años no puedan ser interesantes, sobretodo si se incorpora
              la extensión de garantía al paquete de servicios contratados.
              <br />
              <br />
              En el momento de efectuar el cambio disfrutarás de un coche
              totalmente nuevo, evolucionado tecnológicamente, pero con un coste
              mensual inferior al actual (si se mantienen las mismas condiciones
              del contrato inicial).
            </MKTypography>
          </Box>
        </Container>
        <Box sx={{ marginTop: "10px" }}>
          <Box
            mt={4}
            sx={{
              minHeight: "69vh",
              width: "100%",
              backgroundImage: `url(${backets})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: `${appBarHeight}px`,
              position: "relative", // Necesario para el pseudo-elemento
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `
        linear-gradient(to bottom, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 10%), 
        linear-gradient(to top, rgba(24, 24, 24, 1) 0%, rgba(0, 0, 0, 0) 20%)
      `,
              },
            }}
          >
            {/* El contenido dentro de la caja */}
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid container sx={{ width: "80%" }} spacing={2}>
                <Grid item xs={12} lg={8}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        width: "80%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "left",
                      }}
                    >
                      <Grid item xs={4} p={2}>
                      <Card
      style={{
        backgroundColor: "#181818",
        boxShadow: "0px 10px 42px 10px rgba(73, 245, 116,0.1)",
        borderRadius: "16px",
        padding: "24px",
        overflow: "hidden",
        maxWidth: "400px", // Limitar el ancho para mejorar la legibilidad
        margin: "auto", // Centramos la tarjeta en la página
      }}
    >
      {/* Contenedor del Título */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
      >
        {/* Título Principal */}
        <Typography
          variant="h3" // Aumentamos el tamaño del título
          style={{
            color: "#FFD415",
            textTransform: "capitalize",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          Todo Riesgo
        </Typography>
        
        {/* Subtítulo */}
        <Typography
          variant="h5" // Aumentamos el tamaño del subtítulo
          style={{
            color: "#FFFFFF",
            textTransform: "capitalize",
            fontWeight: "bold",
            opacity: 0.8, // Añadimos opacidad para diferenciarlo
            marginBottom: "16px",
          }}
        >
          Franquicia de 600€ (ó 300€)
        </Typography>
        {/* Divider decorativo */}
        <Divider
          style={{
            width: "60%",
            backgroundColor: "#FFD415",
            height: "2px", // Grosor del divider para destacarlo
          }}
        />
      </Box>
      {/* Contenedor de descripción */}
      <Box mt={0} mb={2}>
        <Typography
          variant="body2"
          style={{
            color: "#ffffff",
            textAlign: "justify",
            marginTop: "12px",
          }}
        >
          Aquí puedes añadir una breve descripción del seguro, destacando
          las ventajas y características principales para que el cliente
          tenga claro qué incluye esta opción de seguro.
        </Typography>
      </Box>
      {/* Divider para separar la descripción del precio */}
      <Divider style={{ backgroundColor: "#ffffff", opacity: 0.2 }} />
      {/* Contenedor del Precio */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography
          variant="h4"
          style={{
            color: "#FFD415",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          49.99€/mes
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: "#ffffff",
            textTransform: "uppercase",
            opacity: 0.7,
          }}
        >
          Precio estimado
        </Typography>
      </Box>
    </Card>
                      </Grid>
                      <Grid item xs={4} p={2}>
                      <Card
      style={{
        backgroundColor: "#181818",
        boxShadow: "0px 10px 42px 10px rgba(73, 245, 116,0.1)",
        borderRadius: "16px",
        padding: "24px",
        overflow: "hidden",
        maxWidth: "400px", // Limitar el ancho para mejorar la legibilidad
        margin: "auto", // Centramos la tarjeta en la página
      }}
    >
      {/* Contenedor del Título */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
      >
        {/* Título Principal */}
        <Typography
          variant="h3" // Aumentamos el tamaño del título
          style={{
            color: "#FFD415",
            textTransform: "capitalize",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          Todo Riesgo
        </Typography>
        
        {/* Subtítulo */}
        <Typography
          variant="h5" // Aumentamos el tamaño del subtítulo
          style={{
            color: "#FFFFFF",
            textTransform: "capitalize",
            fontWeight: "bold",
            opacity: 0.8, // Añadimos opacidad para diferenciarlo
            marginBottom: "16px",
          }}
        >
          Franquicia de 600€ (ó 300€)
        </Typography>
        {/* Divider decorativo */}
        <Divider
          style={{
            width: "60%",
            backgroundColor: "#FFD415",
            height: "2px", // Grosor del divider para destacarlo
          }}
        />
      </Box>
      {/* Contenedor de descripción */}
      <Box mt={0} mb={2}>
        <Typography
          variant="body2"
          style={{
            color: "#ffffff",
            textAlign: "justify",
            marginTop: "12px",
          }}
        >
          Aquí puedes añadir una breve descripción del seguro, destacando
          las ventajas y características principales para que el cliente
          tenga claro qué incluye esta opción de seguro.
        </Typography>
      </Box>
      {/* Divider para separar la descripción del precio */}
      <Divider style={{ backgroundColor: "#ffffff", opacity: 0.2 }} />
      {/* Contenedor del Precio */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography
          variant="h4"
          style={{
            color: "#FFD415",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          49.99€/mes
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: "#ffffff",
            textTransform: "uppercase",
            opacity: 0.7,
          }}
        >
          Precio estimado
        </Typography>
      </Box>
    </Card>
                      </Grid>
                      <Grid item xs={4} p={2}>
                      <Card
      style={{
        backgroundColor: "#181818",
        boxShadow: "0px 10px 42px 10px rgba(73, 245, 116,0.1)",
        borderRadius: "16px",
        padding: "24px",
        overflow: "hidden",
        maxWidth: "400px", // Limitar el ancho para mejorar la legibilidad
        margin: "auto", // Centramos la tarjeta en la página
      }}
    >
      {/* Contenedor del Título */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={3}
      >
        {/* Título Principal */}
        <Typography
          variant="h3" // Aumentamos el tamaño del título
          style={{
            color: "#FFD415",
            textTransform: "capitalize",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          Todo Riesgo
        </Typography>
        
        {/* Subtítulo */}
        <Typography
          variant="h5" // Aumentamos el tamaño del subtítulo
          style={{
            color: "#FFFFFF",
            textTransform: "capitalize",
            fontWeight: "bold",
            opacity: 0.8, // Añadimos opacidad para diferenciarlo
            marginBottom: "16px",
          }}
        >
          Franquicia de 600€ (ó 300€)
        </Typography>
        {/* Divider decorativo */}
        <Divider
          style={{
            width: "60%",
            backgroundColor: "#FFD415",
            height: "2px", // Grosor del divider para destacarlo
          }}
        />
      </Box>
      {/* Contenedor de descripción */}
      <Box mt={0} mb={2}>
        <Typography
          variant="body2"
          style={{
            color: "#ffffff",
            textAlign: "justify",
            marginTop: "12px",
          }}
        >
          Aquí puedes añadir una breve descripción del seguro, destacando
          las ventajas y características principales para que el cliente
          tenga claro qué incluye esta opción de seguro.
        </Typography>
      </Box>
      {/* Divider para separar la descripción del precio */}
      <Divider style={{ backgroundColor: "#ffffff", opacity: 0.2 }} />
      {/* Contenedor del Precio */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography
          variant="h4"
          style={{
            color: "#FFD415",
            fontWeight: "bold",
            marginBottom: "8px",
          }}
        >
          49.99€/mes
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: "#ffffff",
            textTransform: "uppercase",
            opacity: 0.7,
          }}
        >
          Precio estimado
        </Typography>
      </Box>
    </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={4} p={0}>
                  <Box sx={{ width: "100%" }}>
                    <Grid container sx={{ width: "100%" }} spacing={2}>
                      <Grid item xs={12} lg={12}>
                        <MKTypography
                          sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "atomic-regular",
                            letterSpacing: "0.15em",
                            color: "#ffffff",
                            marginTop: "10px", // Ajusta la separación superior según sea necesario
                            transform: "rotate(-2deg)",
                            fontSize: {
                              xs: "3.5rem",
                              sm: "4rem",
                              md: "6rem",
                              lg: "8rem",
                              xl: "2rem",
                            },
                            animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                            textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                          }}
                          variant="h1"
                          align="center"
                          gutterBottom
                        >
                          SEGURO A TODO RIESGO
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-3}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFD415",
                              marginTop: "-10px", // Ajusta la separación superior según sea necesario
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "3.5rem",
                                sm: "4rem",
                                md: "6rem",
                                lg: "8rem",
                                xl: "4rem",
                              },
                              animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            Preference
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Box mt={-4}>
                          <MKTypography
                            sx={{
                              fontFamily: "atomic-regular",
                              letterSpacing: "0.15em",
                              color: "#FFFFFF",
                              marginTop: "-10px", // Ajusta la separación superior según sea necesario
                              transform: "rotate(-2deg)",
                              fontSize: {
                                xs: "3.5rem",
                                sm: "4rem",
                                md: "6rem",
                                lg: "8rem",
                                xl: "1rem",
                              },
                              animation: "blink-animation2 0.2s 3 1s", // Agrega la animación
                              textShadow: "4px 2px 5px rgba(0, 0, 0, 0.05)",
                            }}
                            variant="h1"
                            align="center"
                            gutterBottom
                          >
                            VENTAJAS
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12} p={1}>
                        <Box mt={-0}>
                          <MKTypography
                            variant="body2"
                            sx={{ color: "#FFFFFF", textAlign: "justify" }}
                          >
                            Se pueden presupuestar diferentes modalidades de
                            Seguro. Desde un Todo Riesgo 100% para menores de 31
                            años, hasta un Todo Riesgo con Franquicia de 600€
                            para mayores de 31 años. En todos los casos, si se
                            repara el vehículo en cualquiera de los{" "}
                            <a
                              style={{ color: "#FFD415" }}
                              href="https://www.renault.es/concesionarios.html"
                            >
                              Talleres Oficiales de la Red Renault
                            </a>
                            , se tendrá derecho a una reducción del 50% de la
                            franquicia contratada.
                          </MKTypography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} lg={12} p={1}>
                        <Box mt={-0}>
                          <Card
                            sx={{
                              backgroundColor: "rgba(0, 0, 0, 0.6)",
                              padding: "20px",
                              width: "100%",
                              borderRadius: "8px",
                            }}
                          >
                            <MKBox
                              sx={{
                                justifyContent: "center",
                                display: "flex",
                                position: "relative",
                                flexDirection: "column",
                              }}
                            >
                              <MKTypography
                                variant="h6"
                                sx={{ color: "#a8a8a8", textAlign: "center" }}
                              >
                                ¡MUY IMPORTANTE!
                              </MKTypography>
                              <MKTypography
                                variant="h6"
                                sx={{ color: "#a8a8a8", textAlign: "center", fontSize:'75%' }}
                              >
                                No importa la edad ni la antigüedad del carnet
                                de conducir. Esta particularidad hace que la
                                opción RENAULT PREFERENCE sea ideal -y muy
                                económica- para todos los conductores noveles
                                con menos de 25 años de edad y menos de 2 años
                                de carnet de conducir.
                              </MKTypography>
                            </MKBox>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <MKBox bgColor="info" mt={4}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              {<CenteredFooter pt={6} px={1} />}
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
      
    </>
  );
}

export default KodePreference;
