import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Landing from "pages/Presentation/sections/landing";
import Presentation from "layouts/pages/presentation";
import ProductDetail from "pages/Presentation/sections/ProductDetail";
import Auto from "pages/KodeAuto";
import Blog from "./pages/KodeBlog/index";
import BlogDetail from "./pages/KodeBlog/blogDetail";
import Cookiespop from './cookies';
import Preference from "pages/KodePreference";
import CookieConsent from "./CookieComponent";

import KodeCookies from "pages/Política de Cookies";
import KodePrivacidad from "pages/Política de Privacidad";
import KodeTerms from "pages/Términos y Condiciones";
import KodeLegal from "pages/Aviso Legal";

import FullPageMessage from "pages/Presentation/sections/Testing";
import "./customScrollbar.css";
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route path={route.route} element={route.component} key={route.key} />
        );
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Cookiespop/>
      <Routes>
        <Route path="/" element={<Presentation />} />
        <Route path="/testing" element={<Presentation />} />
        {getRoutes(routes)}
        <Route path="/productos/:id" element={<ProductDetail />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/autoescuela" element={<Auto />} />
        <Route path="/preference" element={<Preference />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/politica-de-privacidad" element={<KodePrivacidad />} />
        <Route path="/politica-de-cookies" element={<KodeCookies />} />
        <Route path="/terminos-y-condiciones" element={<KodeTerms />} />
        <Route path="/aviso-legal" element={<KodeLegal />} />

      </Routes>
      
    </ThemeProvider>
  );
}
