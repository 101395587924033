import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Divider from "@mui/material/Divider";
import icon_engine from "assets/images/icon-engine.png";
import icon_gear from "assets/images/icon-gear.png";
import icon_delivery from "assets/images/icon-delivery.png";
import { Box } from "@mui/material";
import { Opacity, Padding } from "@mui/icons-material";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { Chip } from '@mui/material';

function CenteredBlogCardProduct2({
  image,
  title,
  description,
  action,
  precio,
  medio,
  motor,
  cambio,
  cuota,
  espec,
  entrega,
  modelo2,
  ambient,
}) {
  const isMobileDevice = () => {
    return window.innerWidth <= 500; // Puedes ajustar este valor según tus necesidades
  };
  const navigate = useNavigate();
  function dividirTextoEnLineas(texto, palabrasPorLinea) {
    const palabras = texto.split(" ");
    let lineas = [];
    let lineaActual = "";
    palabras.forEach((palabra, index) => {
      lineaActual += palabra + " ";
      if (
        (index + 1) % palabrasPorLinea === 0 ||
        index === palabras.length - 1
      ) {
        lineas.push(lineaActual.trim());
        lineaActual = "";
      }
    });
    return lineas;
  }

  const palabrasPorLinea = 5; // Número de palabras por línea
  const lineasDescripcion = dividirTextoEnLineas(description, palabrasPorLinea);
  const [price, setPrice] = useState(0);
  const [cuote, setCuote] = useState(0);
  const [moves, setmoves] = useState(false);


  useEffect(() => {
    const ambientValue = parseInt(ambient, 10);  
  
    if (ambientValue === 0) {
      setmoves(true);
    } else if (ambientValue <= 110) {
      setPrice(0);
      setCuote(25.21);
    } else if (ambientValue <= 159) {
      setPrice(35.63);
      setCuote(26.20);
    } else if (ambientValue <= 199) {
      setPrice(73.13);
      setCuote(27.24);
    } else {
      setPrice(120);
      setCuote(28.54);
    }
  }, [ambient]); 
  

  return (
    <Card style={{ backgroundColor: "#181818", boxShadow: "none" }}>
      <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
        <div
          style={{
            overflow: "hidden",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <img
            src={image}
            alt={title}
            style={{ width: "100%", borderRadius: "0", objectFit: "cover" }}
          />
        </div>
        <MKBox position="absolute" sx={{ bottom: 0, right: 0, mb: '15px', mr: '0px', display: 'flex', alignItems: 'flex-end' }}>
            {moves && (
              <Chip 
                label="Plan MOVES III" 
                sx={{ 
                  color:'#FFFFFF',
                  backgroundColor: '#72c293',
                  ml: 1, 
                  fontSize: '0.70rem', 
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px',

                  height: '25px', 
                  '& .MuiChip-label': {
                    padding: '0 8px', 
                  }
                }} 
              />
            )}
          </MKBox>
      </MKBox>
      <Box
        mb={3}
        sx={{
          borderRadius: "8px",
          backgroundColor: "#FFD415",
          width: "100%",
          mt: 1,
          py: 1,
        }}
      >
        <MKTypography
          variant="body2"
          sx={{ color: "#031b27", fontSize: "60%", fontWeight: "bold" }}
        >
          Precio Renault: {precio}
        </MKTypography>
      </Box>
      <MKBox p={0} mt={0} textAlign="center" alignItems="center">
        <MKBox
          mt={0}
          mb={3}
          mx={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <MKBox flex={1} sx={{ textAlign: "left" }}>
            <MKTypography
              display="inline"
              variant="h4"
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {title.split(" ")[0]}
            </MKTypography>
            <MKTypography
              variant="body2"
              sx={{ color: "#ffffff", fontSize: "75%" }}
            >
              {modelo2}
            </MKTypography>
            <MKBox mt={0} mb={-1} sx={{ height: "50px" }}>
              {lineasDescripcion.map((linea, index) => (
                <MKTypography
                  key={index}
                  variant="body2"
                  component="p"
                  sx={{ fontSize: "65%", color: "#a8a8a8" }}
                >
                  {linea}
                </MKTypography>
              ))}
            </MKBox>
          </MKBox>
          <MKBox>
            <img
              src={medio}
              style={{ width: "100%", borderRadius: "0", objectFit: "cover" }}
            />
          </MKBox>
        </MKBox>
        <MKBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={1}
          sx={{ borderRadius: "6px", padding: "6px", position: "relative" }}
        >
          <img
            src={icon_delivery}
            style={{ width: "24px", marginLeft: "10px", marginRight: "15px" }}
          />
          <MKTypography
            variant="caption"
            component="p"
            sx={{ fontSize: "small", color: "#ffffff" }}
          >
            {entrega}
          </MKTypography>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#a8a8a8",
              opacity: "30%",
              borderRadius: "6px",
            }}
          ></Box>
        </MKBox>
        <Divider variant="middle" />
        <MKBox
          sx={{ minHeight: isMobileDevice() ? "0px" : "50px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={-2}
          mb={0}
        >
          <MKBox
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={icon_engine}
              style={{ width: "24px", marginLeft: "0px" }}
            />
            <MKTypography sx={{ ml: 2, color: "#a8a8a8" }} variant="body2">
              {motor}
            </MKTypography>
          </MKBox>
          <MKBox
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={icon_gear} style={{ width: "24px" }} />
            <MKTypography sx={{ ml: 2, color: "#a8a8a8" }} variant="body2">
              {cambio}
            </MKTypography>
          </MKBox>
        </MKBox>
        <MKBox
          mt={0}
          mb={0}
          mx={0}
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <MKBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginleft: "0px",
            }}
          >
            <h6
              style={{
                color: "#ffffff",
                fontWeight: 400,
                marginRight: "0px",
                fontSize: "1rem",
              }}
            >
              {" "}
              {(
                parseFloat(precio.replace(/\./g, "").replace(",", ".")) -
                (907.5 + price)
              )
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
              €
            </h6>
          </MKBox>
          <MKBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "10px",
            }}
          >
            <h6 style={{ color: "#ffffff", fontSize: "1.2rem" }}>
            {" "}
              {(
                parseFloat(cuota.replace(/\./g, "").replace(",", ".")) -
                (cuote)
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
              € 
            </h6>
            <h6
              style={{ color: "#ffffff", fontWeight: 400, marginLeft: "5px" }}
            >
              {"/Mes"}
            </h6>
          </MKBox>
        </MKBox>
        <MKBox mt={0} mb={3}>
          <MKTypography
            variant="caption"
            component="p"
            sx={{ fontSize: "x-small", color: "#a8a8a8" }}
          >
            {espec}
          </MKTypography>
          <MKTypography
            variant="caption"
            component="p"
            sx={{ fontSize: "x-small", color: "#a8a8a8" }}
          >
            precio y entrega recomendado sujeto a oferta final
          </MKTypography>
        </MKBox>
        <Box sx={{ mt: 2 }}>
        <a href={`/productos/${action}`} target="_blank" rel="noopener noreferrer">
  <Button
    variant="outlined"
    sx={{
      width: "100%",
      borderColor: "#FFD415",
      color: "#FFD415",
      "&:hover": {
        color: "#e91e63",
        border: "1px solid #e91e63",
      },
    }}
  >
    DESCÚBRELO
  </Button>
</a>

        </Box>
      </MKBox>
    </Card>
  );
}

CenteredBlogCardProduct2.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CenteredBlogCardProduct2;
