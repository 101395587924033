import React, { useState, useEffect, useRef } from "react";

import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import RotatingCardProduct from "examples/Cards/RotatingCardProduct";
import RotatingCardFront from "examples/Cards/RotatingCardProduct/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCardProduct/RotatingCardBack";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import MKPagination from "components/MKPagination";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import VistaArbol from "pages/Presentation/sections/FiltrosDropdown";
import { Slider, Button, Select } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CenteredBlogCardProduct from "examples/Cards/BlogCards/CenteredBlogCard Product";
import CenteredBlogCardProduct2 from "examples/Cards/BlogCards/CenteredBlogCard Product2";

function Productos({ carConfigR, carConfigR360, refep }) {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const [totalPages, setTotalPages] = useState(1);
  const [productos, setProductos] = useState([]);
  //const topRef = useRef(null);

  useEffect(() => {
    if (refep.current) {
      const yOffset = -200; // Ajuste de 200px antes del componente
      const y =
        refep.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [currentPage]); // Ejecutar cuando la página cambie

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [carConfigR, carConfigR360]);

  useEffect(() => {
    if (carConfigR) {
      const {
        typeSeleccionado,
        modeloSeleccionado,
        versionSeleccionada,
        colorSeleccionado,
        motorSeleccionado,
        cambioSeleccionado,
        mediosSeleccionado,
      } = carConfigR;
      console.log("test", [
        typeSeleccionado,
        modeloSeleccionado,
        versionSeleccionada,
        colorSeleccionado,
        motorSeleccionado,
        cambioSeleccionado,
        mediosSeleccionado,
      ]);

      if (
        typeSeleccionado &&
        modeloSeleccionado &&
        versionSeleccionada &&
        colorSeleccionado &&
        motorSeleccionado &&
        cambioSeleccionado &&
        mediosSeleccionado
      ) {
        obtenerProductos(
          typeSeleccionado,
          modeloSeleccionado,
          versionSeleccionada,
          colorSeleccionado,
          [],
          motorSeleccionado,
          cambioSeleccionado,
          mediosSeleccionado
        );
      }
    }
  }, [carConfigR, currentPage]);

  useEffect(() => {
    if (carConfigR360) {
      const {
        typeSeleccionado,
        modeloSeleccionado,
        versionSeleccionada,
        colorSeleccionado,
        equipamientoSeleccionado,
      } = carConfigR360;

      if (modeloSeleccionado) {
        obtenerProductos(
          [],
          modeloSeleccionado,
          versionSeleccionada,
          colorSeleccionado,
          equipamientoSeleccionado,
          [],
          [],
          []
        );
      }
    }
  }, [carConfigR360, currentPage]);

  const obtenerProductos = async (
    type,
    modelo,
    version,
    color,
    equipx_id,
    motor,
    cambio,
    medio
  ) => {
    // Calculamos el offset basado en la página actual y los productos por página.
    const offset = (currentPage - 1) * productsPerPage;
    const limit = productsPerPage; // Limite de productos por página
    /*console.log(
      "vehiculos",
      `https://kodeapp.onrender.com/api/productos?modelox_id=${modelo}&versionx_id=${version}&colorx_id=${color}&equipx_id=${equipx_id}&motorx_id=${motor}&cambiox_id=${cambio}&mediox_id=${medio}&limit=${limit}&offset=${offset}`
    );*/
    try {
      const response = await axios.get(
        `https://kodeapp.onrender.com/api/productos?type=${type}&modelox_id=${modelo}&versionx_id=${version}&colorx_id=${color}&equipx_id=${equipx_id}&motorx_id=${motor}&cambiox_id=${cambio}&mediox_id=${medio}&limit=${limit}&offset=${offset}`
      );

      if (response.data.productos && response.data.productos.length > 0) {
        const productosAleatorios = response.data.productos.sort(() => Math.random() - 0.5);
        setProductos(productosAleatorios);
        console.log('vehiculos', [JSON.parse(response.data.productos[0].caract_adicionales)[0][2]['CONSUMOS Y EMISIONES']])
        const totalProductos = response.data.total;
        setTotalPages(Math.ceil(totalProductos / productsPerPage));
      } else {
        console.error(
          "La respuesta del backend no contiene productos válidos."
        );
      }
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  
  return (
    <Box ref={refep} sx={{ flexGrow: 1, textAlign: "center" }}>
      <Grid container spacing={3}>
        {productos.map((producto) => (
          
          <Grid key={producto.id} lg={3} xs={12} sm={6}>
            <CenteredBlogCardProduct2
              image={producto.portada[0]["img"]}
              title={producto.modelo}
              description={producto.version}
              precio={producto.portada[0]["precio"]}
              medio={producto.portada[0]["medio"]}
              motor={producto.portada[0]["motor"]}
              cambio={producto.portada[0]["cambio"]}
              cuota={producto.portada[0]["cuota"].split("/")[0]}
              espec={producto.portada[0]["espec"]}
              entrega={producto.portada[0]["entrega"]}
              modelo2={producto.Modelo_2}
              ambient={
                JSON.parse(producto.caract_adicionales)?.[0]?.[3]?.['CONSUMOS Y EMISIONES']?.['CO2 ciclo mixto WLTP (g/km)'] ??
                JSON.parse(producto.caract_adicionales)?.[0]?.[2]?.['CONSUMOS Y EMISIONES']?.['CO2 ciclo mixto WLTP (g/km)'] ??
                'N/A'
              }
              action={producto.id}

            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 6, width: "100%" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          variant="contained"
          size="large"
          sx={{
            "& .MuiPaginationItem-root.Mui-selected": {
              // Mayor especificidad
              backgroundColor: "#FFD415",
              color: "#181818",
              "&:hover": {
                backgroundColor: "#FFD415", // Fondo en hover para botón seleccionado
                color: "#181818",
              },
            },
            "& .MuiPaginationItem-root": {
              // Estilo para los botones no seleccionados
              backgroundColor: "rgba(255,255,255,0.3)",
              color: "#000",
              "&:hover": {
                backgroundColor: "#FFD415",
                color: "#181818",
              },
            },
            display: "inline-block",
          }}
        />
      </Box>
    </Box>
  );
}

export default Productos;
